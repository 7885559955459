
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    imageSrc: {
      type: String,
      required: true,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    status: {
      type: String,
      required: true,
      default: '',
    },
    isType: {
      type: String,
      required: true,
    },
  }
});
