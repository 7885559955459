const couponTypes = [
  {
    code: 'CDI',
    label: 'Taxi-Charge',
  },
  {
    code: 'CHO',
    label: 'Coupon - Hochelaga',
  },
  {
    code: 'CPP',
    label: 'Coupon - Prestige',
  },
  {
    code: 'CCC',
    label: 'Coupon - Certificat Cadeau',
    canHaveNoAccount: true,
  },
  {
    code: 'CPC',
    label: 'Coupon - Postes Canada',
  },
  {
    code: 'CCT',
    label: 'Coupon - Cool Taxi',
  },
  {
    code: 'ECR',
    label: 'Coupon - Carte NON ECR',
  },
  {
    code: 'CAC',
    label: 'Coupon - Autre compagnie',
  },
];

export default couponTypes;
