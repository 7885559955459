
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    message: {
      type: String,
      required: true,
      default: '',
    },
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const open = computed(() => {
      return props.isOpen;
    });

    const closeModal = () => {
      emit('close');
    }

    const confirmModal = () => {
      emit('confirm');
      closeModal();
    }

    return {
      open,
      closeModal,
      confirmModal,
    };
  },
});
