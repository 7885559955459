import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_item_registration = _resolveComponent("item-registration")!
  const _component_samva_table = _resolveComponent("samva-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "10"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Demandes de mises à jour")
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_samva_table, {
                      tableName: _ctx.table.taxelco,
                      items: _ctx.requestItemsChange,
                      sort: _ctx.table.sortByKey,
                      dir: _ctx.table.sortDir,
                      loading: _ctx.loading,
                      onSort: _cache[0] || (_cache[0] = ($event: any) => (_ctx.table.sorting($event)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_item_registration, {
                          items: _ctx.requestItemsChange,
                          isChange: ""
                        }, null, 8, ["items"])
                      ]),
                      _: 1
                    }, 8, ["tableName", "items", "sort", "dir", "loading"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}