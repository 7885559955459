
import { defineComponent, computed, ref } from 'vue';
import { useCouponStore } from '@/store/useCoupon';
import cropRotate from '@/utils/coupon-crop-rotate';

export default defineComponent({
  name: 'coupon-crop-rotate-original-image',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { saveUrl } = useCouponStore();
    const selectedIitem = ref(null);

    const selections = computed(() => {
      const keys = Object.keys(props.item);
      let data = [];
      cropRotate.forEach((val) => {
        keys.forEach((key) => {
          if (key === val.value) {
            data.push({
              title: val.text,
              value: props.item[key],
              id: props.item['id'],
            });
          }
        });
      });

      return data;
    });

    const selectedImage = async () => {
      const { id, value, title } = selectedIitem.value;
      await saveUrl(id, value, props.item.originalUrl || props.item.url, title);
      close();
    };

    const close = () => {
      context.emit('close');
    };

    return {
      selectedImage,
      selectedIitem,
      close,
      selections,
      cropRotate,
    };
  },
});
