import { reactive } from 'vue';

export const rules = reactive({
  login: {
    email: [
      (v) => !!v || 'Le courriel est requis',
      (v) => /.+@.+\..+/.test(v) || 'Le courriel doit être valide',
    ],
    password: [
      (v) => !!v || 'Mot de passe requis',
      (v) => (v && v.length >= 8) || 'Min de 8 caractères',
    ],
  },
  coupon: {
    type: [(v) => !!v || 'Type de coupon est requis'],
    couponNumber: [
      (v) => !!v || 'Numéro de coupon est requis',
      (v) => /^[a-z0-9]+$/i.test(v) || 'Le numéro de coupon doit être alphanumérique.',
      (v) => (v).toString().length <= 12 || 'Le numéro de coupon ne doit pas dépasser 12 caractères.',
    ],
    accountNumber: [(v) => !!v || 'Numéro de compte est requis'],
    amount: [
      (v) => !!v || 'Amount is required.',
      (v) => {
        const value =
          typeof v === 'string' ? Number(v.replace(/[^\d,-]/g, '').replace(/[,]/g, '.')) : v;
        return value > 0 || 'Le montant doit être un nombre positif';
      },
    ],
    customReason: [
      (v) => !!v || 'Ce champ est obligatoire',
      (v) => (v && v.length <= 30) || '30 caractères maximum',
    ],
  },
});

export const valid = reactive({
  login: true,
  couponOptions: true,
});

export const show = reactive({
  password: false,
});
