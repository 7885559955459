import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { cols: "12" }, {
        default: _withCtx(() => [
          (_ctx.item)
            ? (_openBlock(), _createBlock(_component_v_textarea, {
                key: 0,
                solo: "",
                name: "note",
                label: "Remarques",
                placeholder: "Tapez vos notes...",
                "hide-details": "auto",
                modelValue: _ctx.items.notes,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.items.notes) = $event)),
                variant: "outlined",
                onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addNotes(_ctx.item.id, _ctx.items.notes)))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}