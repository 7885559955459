
import { ref, computed } from 'vue';

export default {
  name: 'erp-url',
  props: {
    url: {
      required: false,
      type: String,
      default: '',
    },
    odooId: {
      required: false,
      type: Number,
      default: 0,
    },
    type: {
      required: false,
      type: String,
      default: 'coupons',
    },
    driverId: {
      required: false,
      type: Number,
      default: 0,
    },
    vehicleId: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const getParams = (type, couponId, vehicleId, driverId) => {
      if (type === 'driver') {
        return {
          id: driverId.toString(),
          action: '145',
          model: 'res.partner',
          view_type: 'form',
          menu_id: '123',
        };
      }

      if (props.type === 'vehicle') {
        return {
          id: vehicleId.toString(),
          action: '395',
          active_id: driverId.toString(),
          model: 'fleet.vehicle',
          view_type: 'form',
          menu_id: '123',
        };
      }

      return {
        id: couponId.toString(),
        model: 'vehicle.trip',
        view_type: 'form',
        menu_id: '102',
      };
    };

    const createUrl = (type, couponId, vehicleId, driverId) => {
      const baseUrl = process.env.VUE_APP_ERP_URL;
      const params = getParams(type, couponId, vehicleId, driverId)
      const paramsStr = new URLSearchParams(params).toString();

      const url = baseUrl + 'web#' + paramsStr;

      return url;
    };

    const erpUrl = computed(() => {
      return props.url
        || createUrl(props.type, props.odooId, props.vehicleId, props.driverId);
    });

    const hiddenUrl = ref(null);

    const copyUrl = async (url) => {
      try {
        const type = 'text/plain';
        const blob = new Blob([url], { type });
        const data = new window.ClipboardItem({ [type]: blob });
        await navigator.clipboard.write([data]);
      } catch (error) {
        copyUrlFallback();
      }
    };

    const copyUrlFallback = () => {
      hiddenUrl.value.setAttribute('type', 'text');
      hiddenUrl.value.select();
      try {
        const successful = document.execCommand('copy');
        console.log(successful);
      } catch (err) {
        console.log(err);
      }
      hiddenUrl.value.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    };

    return {
      erpUrl,
      hiddenUrl,
      copyUrl,
    };
  },
};
