import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_request_approbation_button = _resolveComponent("request-approbation-button")!

  return (_openBlock(), _createElementBlock("tbody", null, [
    _createElementVNode("tr", null, [
      _createElementVNode("td", null, _toDisplayString(_ctx.item && _ctx.item.submitted && _ctx.item.submitted.profil.givenName ? _ctx.item.submitted.profil.givenName : 'Aucun'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.item && _ctx.item.submitted && _ctx.item.submitted.profil.lastName ?  _ctx.item.submitted.profil.lastName : 'Aucun'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.item && _ctx.item.submitted && _ctx.item.submitted.profil.email ? _ctx.item.submitted.profil.email : 'Aucun'), 1),
      _createElementVNode("td", null, _toDisplayString(_ctx.item && _ctx.item.submitted && _ctx.item.submitted.profil.phoneNumber ? _ctx.item.submitted.profil.phoneNumber : 'Aucun'), 1),
      _createElementVNode("td", null, [
        (_ctx.item)
          ? (_openBlock(), _createBlock(_component_request_approbation_button, {
              key: 0,
              section: _ctx.item.approvals.profil,
              id: "profil",
              docId: _ctx.item.id,
              requestType: _ctx.requestType
            }, null, 8, ["section", "docId", "requestType"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}