
import { computed } from 'vue';
import reasonsToRefuse from '@/utils/coupons-reasons-to-refuse';

export default {
  name: 'coupon-approbation-buttons',
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    loadingError: {
      type: Boolean,
      required: true,
      default: false,
    },
    successBtnLabel: {
      type: String,
      required: true,
      default: 'Soumettre',
    },
    withErrors: {
      type: Boolean,
      required: true,
      default: true,
    },
    data: {
      type: Object
    },
  },
  setup(props) {
    const disableBtn = computed(() => {
      const { currentScanner, batchId } = props.data;

      // check for batchId value in scanner pages
      if (!currentScanner.isAppMembre) {
        return props.withErrors || (batchId === '');
      }

      return props.withErrors;
    });

    const reasons = computed(() => {
      if (!props.data.currentScanner.isAppMembre) {
        return reasonsToRefuse.filter(reason => !reason.isAppMembreOnly);
      }
      return reasonsToRefuse;
    });

    return { reasonsToRefuse, disableBtn, reasons };
  },
};
