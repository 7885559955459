
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useChangeStore } from '@/store/useChange';
import { table } from '@/store/stateless/tables';

export default defineComponent({
  setup() {
    const registration = useChangeStore();
    const { requestItemsChange, loading } = storeToRefs(registration);
    const { fetchRequestItemChanges } = useChangeStore();
    const unsubChanges = ref(null);

    onMounted(async () => {
      unsubChanges.value = await fetchRequestItemChanges();
    });

    onUnmounted(async () => {
      await unsubChanges.value();
    });

    return {
      loading,
      requestItemsChange,
      table,
    };
  },
});
