import { defineStore } from 'pinia';
import { db, func } from '@/core/firebaseInit';
import ocrFloss from '@/utils/ocr-floss.js';
import { collection, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useCouponStore } from '@/store/useCoupon';
import { httpsCallable } from 'firebase/functions';

export const useCouponImageStore = defineStore('couponImage', {
  state: () => ({
    coupon: useCouponStore(),
    ocrFloss,
    ocrQuery: collection(db, 'coupons-ocr'),
    metadata: { contentType: 'image/jpeg' },
    ocr: null,
    rotation: 0,
    metaDrawned: false,
    imageLoaded: false,
    imageObj: null,
    elements: [],
    progress: 0,
    uploadStatus: '',
    uploadError: '',
    canvas: null,
  }),

  getters: {
    getRotation(state) {
      return state.rotation;
    },
  },

  actions: {
    setOCRDocType(ocr_id) {
      return doc(db, 'coupons-ocr', ocr_id);
    },

    async fetchOcr(ocr_id, canvas) {
      const ocr = await getDoc(this.setOCRDocType(ocr_id));
      this.ocr = ocr.data();
      this.triggerDraw(canvas);
    },

    draw(ocr, ctx, width) {
      ctx.strokeStyle = '#ffa33d';
      ctx.beginPath();
      ctx.moveTo(0, ocr.offset);
      ctx.lineTo(width, ocr.offset);
      ctx.stroke();

      ocr.results.forEach((result) => {
        // eslint-disable-next-line no-param-reassign
        ctx.strokeStyle = '#ff3f00';
        ctx.strokeRect(
          result.rectangle.left,
          result.rectangle.top + ocr.offset,
          result.rectangle.width,
          result.rectangle.height,
        );
      });
    },

    triggerDraw(canvas) {
      if (this.metaDrawned) return;
      if (!this.ocr) return;
      if (!this.imageLoaded) return;
      if (!this.ocr.results.length) return;
      if (!canvas) return;
      this.metaDrawned = true;
      this.ocr.results = ocrFloss(this.ocr.results, canvas.width);
      this.draw(this.ocr, canvas.getContext('2d'), canvas.width);
    },

    getElements(canvas) {
      if (this.ocr || this.imageLoaded) return [];
      const ratio = canvas.clientWidth / canvas.width;
      let data = [];
      if (this.ocr) {
        data = this.ocr.results.map((result) => ({
          bottom: `${(canvas.height - this.ocr.offset + 20) * ratio}px`,
          left: `${result.rectangle.left * ratio}px`,
          text: result.text,
          Object: result.object,
        }));
      }
      this.elements = data;
    },

    rotateImage(canvas, imageObj) {
      this.rotation = this.rotation + 90;
      if (this.rotation >= 360) this.rotation = 0;
      if (this.rotation === 180 || this.rotation === 0) {
        canvas.width = imageObj.width;
        canvas.height = imageObj.height;
      } else {
        canvas.width = imageObj.height;
        canvas.height = imageObj.width;
      }
      const ctx = canvas.getContext('2d');
      ctx.rotate((this.rotation * Math.PI) / 180);
      if (this.rotation === 90) {
        ctx.translate(0, -canvas.width);
      } else if (this.rotation === 180) {
        ctx.translate(-canvas.width, -canvas.height);
      } else if (this.rotation === 270) {
        ctx.translate(-canvas.height, 0);
      }
      ctx.drawImage(imageObj, 0, 0);
    },

    async uploadImage(file, item) {
      this.coupon.loading = true;
      this.coupon.setError('', false);
      const storage = getStorage();
      const storageRef = ref(storage, `coupons/${item.id}`);
      const uploadTask = uploadBytesResumable(storageRef, file, this.metadata);

      uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.progress = `Uploading ${progress}%`;
        switch (snapshot.state) {
          case 'paused':
            this.uploadStatus = 'Le téléchargement est suspendu';
            break;
          case 'running':
            this.uploadStatus = 'Téléchargement en cours';
            break;
        }
        this.coupon.loading = true;
      });

      try {
        const url = await uploadTask.then((snapshot) => getDownloadURL(snapshot.ref));
        await this.coupon.saveUrl(item.id, url, item.originalUrl || item.url);

        return url;
      } catch (error) {
        switch (error.code) {
          case 'storage/unauthorized':
            this.uploadError = `L'utilisateur n'a pas l'autorisation d'accéder à l'objet`;
            break;
          case 'storage/canceled':
            this.uploadError = `L'utilisateur a annulé le téléchargement`;
            break;
          case 'storage/unknown':
            this.uploadError = `Une erreur inconnue s'est produite, inspectez ${error.serverResponse}`;
            break;
        }
        this.coupon.setError(error);
        this.coupon.loading = false;
        throw error;
      }
    },

    async imageCrop(uid) {
      try {
        this.coupon.loading = true;
        const image = await httpsCallable(func, 'image-CropRotate');
        const result = await image({ uid });
        this.coupon.loading = false;
        return result;
      } catch (err) {
        this.coupon.loading = false;
        console.log(err);
      }
    },
  },
});
