export default [
    // {
    //     text: 'Url',
    //     value: 'url',
    // },
    {
        text: 'Original Image',
        value: 'originalUrl',
    },
    {
        text: 'Crop Image',
        value: 'cropUrl',
    },
    {
        text: 'Rotate Image',
        value: 'rotateUrl',
    },
];
