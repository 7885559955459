import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aed68c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-card-body" }
const _hoisted_2 = { class: "d-flex justify-center" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { ref: "canvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    transition: "dialog-top-transition",
    class: "modal-card"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { class: "grey lighten-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode("Rognage & rotation précise")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                color: "grey",
                density: "compact",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close-circle")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createElementVNode("section", _hoisted_1, [
            _createVNode(_component_v_slider, {
              modelValue: _ctx.preciseRotation,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.preciseRotation) = $event)),
              "thumb-label": "always",
              min: -270,
              max: 270,
              step: 1,
              "hide-details": true
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_v_btn, {
                icon: "",
                flat: "",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.rotate(-90)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-restore")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                flat: "",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.rotate(90)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-reload")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("canvas", _hoisted_4, null, 512)
            ])
          ]),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "elevated",
                color: "success",
                onClick: _ctx.upload
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Appliquer les changements ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Annuler ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}