import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, { transition: "dialog-top-transition" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { class: "grey lighten-5" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, { class: "text-h5" }, {
                default: _withCtx(() => [
                  _createTextVNode("Sélectionnez l'image")
                ]),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                icon: "",
                color: "grey",
                density: "compact",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-close-circle")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          }),
          _createElementVNode("div", null, [
            _createVNode(_component_v_radio_group, {
              modelValue: _ctx.selectedIitem,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedIitem) = $event)),
              inline: ""
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selections, (selection, index) => {
                  return (_openBlock(), _createBlock(_component_v_radio, {
                    class: "selection-image",
                    key: index,
                    value: selection
                  }, {
                    label: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        size: "250",
                        rounded: "0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            src: selection.value,
                            "lazy-src": selection.value,
                            cover: ""
                          }, null, 8, ["src", "lazy-src"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createVNode(_component_v_card_actions, { class: "justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "elevated",
                color: "success",
                onClick: _ctx.selectedImage
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Sélectionnez ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                onClick: _ctx.close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Annuler ")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}