import '@/styles/main.scss';

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import vuetify from './plugins/vuetify';
import { firebaseApp } from '@/core/firebaseInit';
import Components from '@/core/components';
import auth from './core/auth';
import 'cropperjs/dist/cropper.min.css';
// import { loadFonts } from './plugins/webfontloader'

// loadFonts()


const app = createApp(App);

Components(app);

app.use(firebaseApp);
app.use(createPinia());
app.use(router);
app.use(vuetify);
auth(router);

router.isReady().then(() => app.mount('#app'));
