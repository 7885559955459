
import couponTypes from '@/utils/coupon-types';
import { defineComponent, ref, onMounted } from 'vue';
import router from '@/router';

export default defineComponent({
  name: 'one-coupon-filter',
  setup(_, { emit }) {
    const routerId = ref(router.currentRoute.value.params.id);

    const types = routerId.value === 'app-membre'
      ? couponTypes.filter((ct) => ['CDI', 'CHO', 'CCC', 'CPP', 'ECR'].includes(ct.code))
      : couponTypes;

    const filterCoupons = async () => {
      if (selectedTypes.value.length === types.length) {
        // selectedTypes set to null to fetch all, including coupons without types
        emit('fetch', null);
      } else {
        emit('fetch', selectedTypes.value);
      }
      saveToLocalStorage();
    };

    const selectedTypes = ref([]);

    const saveToLocalStorage = () => {
      // save selected types to localStorage
      const stringified = JSON.stringify(selectedTypes.value);
      localStorage.setItem(`${routerId.value}-coupon-type-filter`, stringified);
    };

    onMounted(() => {
      try {
        // get user selected types from localStorage
        const userSelectedTypes = localStorage
          .getItem(`${routerId.value}-coupon-type-filter`,);
        const parsedTypes = JSON.parse(userSelectedTypes);
        if (!parsedTypes) throw Error;
        selectedTypes.value = parsedTypes;
      } catch (error) {
        // default types
        selectedTypes.value = types.map((type) => type.code);
      }
      filterCoupons();
    });

    return {
      selectedTypes,
      types,
      filterCoupons,
    };
  },
});
