
import { defineComponent } from 'vue';
import { useCouponStore } from '@/store/useCoupon';
import { storeToRefs } from 'pinia';
import lists from '@/utils/coupon-list.js';

export default defineComponent({
  setup() {
    const coupon = useCouponStore();

    const { listCoupon, listCouponOpen } = storeToRefs(coupon);

    return {
      lists,
      listCoupon,
      listCouponOpen,
    };
  },
});
