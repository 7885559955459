import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("tr", { key: index }, [
        _createElementVNode("td", null, _toDisplayString(item.name ? item.name : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.ref ? item.ref : '-'), 1)
      ]))
    }), 128))
  ]))
}