
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useCouponStore } from '@/store/useCoupon';
import { print } from '@/store/stateless/print';

export default defineComponent({
  props: {
    totalAmount: {
      required: true,
      type: String,
    },
    processedCount: {
      required: true,
      type: Number,
    },
    vehiclePlate: {
      required: true,
      type: Object,
    },
    driverLicence: {
      required: true,
      type: String,
    },
    batchId: {
      required: true,
      type: String,
    }
  },
  setup() {
    const coupon = useCouponStore();
    const {
      openImport,
      enableClear,
      currentScanner,
      timing,
    } = storeToRefs(coupon);

    const printReceipt = () => {
      print.receipt('print');
    };

    const closeReport = () => {
      openImport.value = false;
      enableClear.value = true;
    }

    return {
      openImport,
      currentScanner,
      timing,
      print,
      printReceipt,
      closeReport,
    };
  },
});
