<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h1>Sélectionnez un scanner</h1>
      </v-col>
    </v-row>
    <card-coupon />
  </v-container>
</template>
