import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_onboarding_profile_board = _resolveComponent("onboarding-profile-board")!

  return (_openBlock(), _createBlock(_component_onboarding_profile_board, {
    items: _ctx.requestItems,
    isType: "request",
    onDelete: _ctx.deleteRequestItem
  }, null, 8, ["items", "onDelete"]))
}