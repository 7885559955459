
import { computed } from 'vue-demi';
export default {
  props: {
    status: {
      type: String,
      required: true,
      default: 'New',
    },
  },

  setup(props) {
    const color = computed(() => {
      const state = props.status.toLowerCase();
      if (state === 'approved') {
        return 'success';
      } else if (state === 'disapproved') {
        return 'error';
      } else {
        return 'grey';
      }
    });

    return {
      color
    };
  },
};
