export default [
  {
    address: [
      {
        code: 'wrong-door-street-number',
        label: 'Mauvais Numéro de porte et rue',
      },
      {
        code: 'wrong-town',
        label: 'Mauvaise Ville',
      },
      {
        code: 'wrong-app-suite',
        label: 'Mauvais App Suite',
      },
      {
        code: 'wrong-province',
        label: 'Mauvaise Province',
      },
      {
        code: 'wrong-code-postal',
        label: 'Mauvais Code Postal',
      },
      {
        code: 'wrong-address',
        label: 'Mauvaise Adresse',
      },
    ],
    vehicleDetails: [
      {
        code: 'wrong-year',
        label: 'Mauvaise année',
      },
      {
        code: 'wrong-brand',
        label: 'Mauvaise Marque',
      },
      {
        code: 'wrong-model',
        label: 'Mauvais Modèle',
      },
      {
        code: 'wrong-energy',
        label: 'Mauvaise Énergie',
      },
      {
        code: 'wrong-color',
        label: 'Mauvaise Couleur',
      },
      {
        code: 'invalid-photo',
        label: 'Photo non valide',
      },
      {
        code: 'illegible-picture',
        label: 'Image Ilisible',
      },
    ],
    taxes: [
      {
        code: 'wrong-tps-number',
        label: 'Mauvais numéro de TPS',
      }, {
        code: 'wrong-tvq-number',
        label: 'Mauvais numéro de TVQ',
      }, {
        code: 'wrong-taxi-number',
        label: 'Mauvais numéro de Taxes',
      },
    ],
    bankAccount: [
      {
        code: 'wrong-transit-number',
        label: 'Mauvais numéro de transit',
      }, {
        code: 'wrong-institution-number',
        label: 'Mauvais numéro de transit',
      }, {
        code: 'wrong-account-number',
        label: 'Mauvais numéro de compute',
      }, {
        code: 'invalid-photo',
        label: 'Photo non valide',
      },
      {
        code: 'illegible-picture',
        label: 'Image Ilisible',
      },
    ],
    driver: [
      {
        code: 'wrong-pocket-number',
        label: 'Mauvais numéro de Pocket',
      }, {
        code: 'wrong-driver-license-number',
        label: 'Mauvais numéro de permis de chauffeur',
      }, {
        code: 'invalid-photo',
        label: 'Photo non valide',
      }, {
        code: 'illegible-picture',
        label: 'Image Ilisible',
      },
    ],
    vehicleRegistration: [
      {
        code: 'wrong-plate-number',
        label: 'Mauvais numéro de plaque',
      }, {
        code: 'invalid-photo',
        label: 'Photo non valide',
      }, {
        code: 'illegible-picture',
        label: 'Image Ilisible',
      },
    ],
    profil: [
      {
        code: 'invalid-photo', label: 'Photo non valide',
      }],
    vehicleCertificate: [
      {
        code: 'invalid-photo', label: 'Photo non valide',
      }],

  }];
