
import router from '@/router';
import { computed, defineComponent, Ref, toRef } from 'vue';

export default defineComponent({
  props: {
    itemDatas: {
      type: Array,
      required: true,
      default: () => [],
    },
    isType: {
      type: String,
      required: true,
      default: 'request',
    },
  },

  setup(props) {
    /* eslint-disable */
    const items: Ref<any> = toRef(props, 'itemDatas');

    const type = computed(() => {
      return props.isType === 'request' ? 'request' : 'request-change';
    });

    const nextItemId = computed(() => {
      if (router.currentRoute != undefined) {
        const index = items.value.findIndex(
          (r: any) => r.id === router.currentRoute.value.params.id,
        );
        if (index === -1 || index === items.value.length - 1) return '';
        return items.value[index + 1].id;
      }
      return '';
    });

    const previousItemId = computed(() => {
      if (router.currentRoute != undefined) {
        const index = items.value.findIndex((r) => r.id === router.currentRoute.value.params.id);
        if (index <= 0) return '';
        return items.value[index - 1].id;
      }
      return '';
    });

    const isFirstItem = computed(() => {
      return previousItemId.value === '';
    });

    const isLastItem = computed(() => {
      return nextItemId.value === '';
    });
    return {
      nextItemId,
      previousItemId,
      isFirstItem,
      isLastItem,
      type,
    };
  },
});
