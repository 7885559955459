
import { defineComponent, toRef } from 'vue';
import { useRegistrationStore } from '@/store/useRegistration';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
  },

  setup(props) {
    const { addNotes } = useRegistrationStore();
    const items = toRef(props, 'item');
    return {
      items,
      addNotes,
    };
  },
});
