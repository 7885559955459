
import { defineComponent, ref } from 'vue';
import { useFunctionStore } from '@/store/useFunctions';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    holder: {
      type: String,
      required: false,
      default: 'Permis de conduire',
    },
  },

  setup(props, context) {
    const { lookupPoket } = useFunctionStore();
    const errorMessage = ref(null);
    const lookupResult = ref(null);
    const working = ref(false);

    const update = (event) => {
      context.emit('update:modelValue', event.target.value);
    };

    const reset = () => {
      errorMessage.value = null;
      lookupResult.value = null;
      context.emit('lookup', null);
    };

    const lookupOdooId = async () => {
      if (!props.modelValue) return;

      working.value = true;
      lookupResult.value = null;
      try {
        const driverLicence = await lookupPoket(props.modelValue);

        working.value = false;
        lookupResult.value = {
          succeeded: true,
        };
        context.emit('lookup', driverLicence.data);
      } catch (e) {
        errorMessage.value = e.message;
        working.value = false;
        lookupResult.value = {
          succeeded: false,
        };
      }
    };

    return {
      working,
      errorMessage,
      lookupResult,
      lookupOdooId,
      update,
      reset,
    };
  },
});
