export default [
  {
    label: 'Adresse',
    field: 'address',
    isDone: false,
    subField: [
      {
        label: 'Numéro de porte et rue',
        field: 'addressLine',
      },
      {
        label: 'Ville',
        field: 'city',
      },
      {
        label: 'Apt, suite #',
        field: 'apt',
      },
      {
        label: 'Province',
        field: 'province',
      },
      {
        label: 'Code postal',
        field: 'postalCode',
      },
    ],
  },
  {
    label: 'Permis chauffeur',
    field: 'driver',
    isDone: false,
    subField: [
      {
        label: 'Numéro de pocket',
        field: 'pocket',
      },
      {
        label: 'Numéro de permis chauffeur',
        field: 'licenseNumber',
      },
      {
        label: 'Image',
        field: 'imageUrl',
      },

    ],
  },
  {
    label: 'Compte pour dépôt',
    field: 'bankAccount',
    isDone: false,
    subField: [
      {
        label: 'Numéro de transit',
        field: 'transit',
      },
      {
        label: "Numéro d'institution",
        field: 'institution',
      },
      {
        label: 'Numéro du compute',
        field: 'account',
      },
      {
        label: 'Image',
        field: 'imageUrl',
      },

    ],
  },
  {
    label: 'Numéros de taxes',
    field: 'taxes',
    isDone: false,
    subField: [
      {
        label: 'Numéro de TPS (12 chiffres)',
        field: 'tps',
      },
      {
        label: 'Numéro de TVQ (10 chiffres)',
        field: 'tvq',
      },
    ],
  },
  {
    label: 'Détails du véhicule',
    field: 'vehicleDetails',
    isDone: false,
    subField: [
      {
        label: 'Année',
        field: 'year',
      },
      {
        label: 'Marque',
        field: 'brand',
      },
      {
        label: 'Modéle',
        field: 'model',
      },
      {
        label: 'Énergie',
        field: 'energy',
      },
      {
        label: 'Couleur',
        field: 'color',
      },
      {
        label: 'Image',
        field: 'imageUrl',
      },
    ],
  },
  {
    label: 'Attestation du véhicule',
    field: 'vehicleCertificate',
    isDone: false,
    subField: [
      {
        label: 'Image',
        field: 'imageUrl',
      },
    ],
  },
  {
    label: 'Immatriculation',
    field: 'vehicleRegistration',
    isDone: false,
    subField: [
      {
        label: "Numéro de plaque d'immatriculation",
        field: 'registrationNumber',
      },
      {
        label: 'Image',
        field: 'imageUrl',
      },
    ],
  },
];
