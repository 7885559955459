
import { ref, watchEffect } from 'vue'
export default {
  props: {
    isOpen: Boolean,
    message: {
      type: String,
      required: true,
      default: ''
    },
    color: {
      type: String,
      required: false,
      default: 'deep-purple-accent-4'
    },
    location: {
      type: String,
      required: false,
      default: 'top right'
    }
  },

  setup(props) {
    const open = ref(false);

    watchEffect(() => {
      open.value = props.isOpen;
    });

    return { open }
  }
}
