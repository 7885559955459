
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, toRef, ref, watchEffect } from 'vue';
import { useCouponImageStore } from '@/store/useCouponImage';

export default defineComponent({
  props: {
    item: {
      required: true,
      type: Object,
    },
  },

  setup(props) {
    const couponImage = useCouponImageStore();
    const { rotation, ocr, elements } = storeToRefs(couponImage);
    const { triggerDraw, getElements, rotateImage } = useCouponImageStore();

    const item = toRef(props, 'item');
    const canvas = ref(null);
    const imageObj = ref(null);


    onMounted(() => {
      if (canvas.value && canvas.value.width && item.value) {
        getElements(canvas.value);
        const context = canvas.value.getContext('2d');
        imageObj.value = new Image();
        imageObj.value.crossOrigin = 'Anonymous';
        imageObj.value.onload = () => {
          if (canvas.value && imageObj.value && imageObj.value.width) {
            canvas.value.width = imageObj.value.width;
            canvas.value.height = imageObj.value.height;
            context.drawImage(imageObj.value, 0, 0);
            triggerDraw(canvas.value);
          }
        };
        imageObj.value.src = item.value.url;
      }
    });

    watchEffect(async () => {
      if (imageObj.value && imageObj.value.src) {
        imageObj.value.src = item.value.url;
      }
    });

    const getRotation = () => {
      return rotation.value;
    };

    return {
      canvas,
      rotation,
      ocr,
      imageObj,
      elements,
      triggerDraw,
      rotateImage,
      getRotation,
    };
  },
});
