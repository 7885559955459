<template>
    <div class="hero">
    </div>
</template>

<style lang="scss" scoped>
.hero {
  background: url('../../assets/images/login-background.jpg');
  background-size: cover;
  height: 100vh;
}
</style>