
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    items: {
      type: Object,
      required: false,
      default: null,
    },
  },
});
