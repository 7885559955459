import { reactive } from 'vue';

let myTable = ['Prénom', 'Nom de famille', 'Courriel', 'Numéro de téléphone', 'Status'];
let listsTable = ['Account Number', 'Coupon Number', 'Source', 'State', 'Type', 'Status', 'Import'];
let accountListTable = ['Name', 'Account Number'];
let columnOrder = {};
myTable.forEach(element => {
    columnOrder[element] = -1;
});

export const table = reactive({
    taxelco: myTable,
    couponLists: listsTable,
    accountLists: accountListTable,
    columnOrder,
    sortByKey: null,
    sortDir: 'asc',

    sorting(e) {
        this.sortByKey = e;
        this.columnOrder[e] = this.columnOrder[e] * -1;
        this.sortDir = this.columnOrder[e] == 1 ? 'asc' : 'desc';
    }
});
