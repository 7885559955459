
import { computed, defineComponent, toRef, ref } from 'vue';
import { useFunctionStore } from '@/store/useFunctions';
import { useRegistrationStore } from '@/store/useRegistration';
import router from '@/router';

export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    isType: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const items = toRef(props, 'items');
    const { importRegistrationInOdoo } = useFunctionStore();
    const { dynamicInsert } = useRegistrationStore();

    const confirmOpen = ref(false);

    const routeName = computed(() => {
      return router.currentRoute.value.name;
    });

    /* eslint-disable */
    const requestItem: any = computed(() => {
      const item = items.value.find((r: any) => r.id === router.currentRoute.value.params.id);
      return item;
    });

    const isDeleted = computed(() => {
      return requestItem.value && requestItem.value.approvals.globalStatus === 'deleted';
    });

    const isEnableButton = computed(() => {
      if (requestItem.value) {
        const data = Object.values(requestItem.value.approvals)
          .filter((val) => {
            if (typeof val === 'object') return val;
          })
          .filter((x: any) => x.status !== 'Approved');

        return data.length > 0 ? true : false;
      }

      return true;
    });

    const sendRegistrationToOdoo = async (registrationId) => {
      if (!registrationId) return;

      try {
        dynamicInsert(registrationId, 'importStatus', 'import-in-odoo')

        await importRegistrationInOdoo(registrationId);

      } catch (error) {
        /// error
      }
    };

    return {
      isEnableButton,
      routeName,
      requestItem,
      isDeleted,
      confirmOpen,
      dynamicInsert,
      sendRegistrationToOdoo
    };
  },
});
