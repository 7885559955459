import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    variant: $setup.variant,
    "hide-details": $setup.hideDetails,
    density: $setup.density,
    rules: $props.rules,
    disabled: $setup.isDisabled,
    class: "currency-input",
    ref: "inputRef"
  }, null, 8, ["variant", "hide-details", "density", "rules", "disabled"]))
}