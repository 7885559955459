export default [
  { code: 'invalid-photo', label: 'Photo invalide', isAppMembreOnly: true },
  { code: 'illegible-writing', label: 'Écriture illisible', isAppMembreOnly: true },
  { code: 'expired', label: 'Coupon expiré', isAppMembreOnly: true },
  { code: 'image-illigible', label: 'Image illisible', isAppMembreOnly: true },
  { code: 'bad-amount', label: 'Mauvais montant', isAppMembreOnly: true },
  { code: 'bad-account-number', label: 'Numéro de compte inexistant', isAppMembreOnly: true },
  { code: 'deposit-in-person', label: 'À déposer en personne au bureau', isAppMembreOnly: true },
  { code: 'coupon-already-submitted', label: 'Coupon déjà soumis', isAppMembreOnly: false },
  { code: 'coupon-already-payed', label: 'Coupon déjà payé', isAppMembreOnly: false },
  { code: 'other', label: 'Autre', isAppMembreOnly: false },
];
