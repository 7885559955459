
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useRegistrationStore } from '@/store/useRegistration';
import { table } from '@/store/stateless/tables';

export default defineComponent({
  setup() {
    const registration = useRegistrationStore();
    const { requestItems, loading } = storeToRefs(registration);
    const { fetchRequestItems } = useRegistrationStore();
    const unsubRequests = ref(null);

    onMounted(async () => {
      unsubRequests.value = await fetchRequestItems();
    });

    onUnmounted(async () => {
      await unsubRequests.value();
    });

    return {
      loading,
      requestItems,
      table,
    };
  },
});
