export default [
  // {
  //   label: 'Image',
  //   field: 'imageUrl',
  // },
  // {
  //   label: 'ID',
  //   field: 'keyId',
  //   isDone: false,
  //   decision: '',
  // },
  {
    label: 'Prénom',
    field: 'givenName',
  },
  {
    label: 'Nom de famille',
    field: 'lastName',
  },
  {
    label: 'Numéro de téléphone',
    field: 'phoneNumber',
  },
  {
    label: 'Courriel',
    field: 'email',
  },
];
