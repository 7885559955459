
import { BatchData, Coupon } from '@/models/coupon.model';
import { useCouponStore } from '@/store/useCoupon';
import { toRef, computed, PropType } from 'vue';
import { storeToRefs } from 'pinia';
export default {
  name: 'coupon-data',
  props: {
    item: {
      required: true,
      type: Object as PropType<Coupon>,
    },
    batchData: {
      type: Object as PropType<BatchData>,
      default: {} as BatchData,
    },
  },
  setup(props) {
    const coupon = useCouponStore();
    const { currentScanner } = storeToRefs(coupon);
    const item = toRef(props, 'item');

    const batchId = computed(() => item.value.batchId || props.batchData.batchId);
    const driverId = computed(() => item.value.driverId || props.batchData.driverId);
    const vehicleId = computed(() => item.value.vehicleId || props.batchData.vehicleId);
    // returns driverLicence or driverId
    const driverData = computed(() =>
      props.batchData.driverLicence || item.value.driverLicence || driverId.value);
    const vehicleData = computed(() => {
      // returns vehiclePlate(vignette) or vehicleId id
      if (props.batchData.vehicle) {
        return props.batchData.vehicle.vignette;
      }
      return item.value.vehiclePlate || vehicleId.value;
    });

    return {
      currentScanner,
      batchId,
      driverId,
      vehicleId,
      driverData,
      vehicleData,
    };
  }
}
