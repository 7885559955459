import {
  collection,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  doc,
} from 'firebase/firestore';
import { db } from '@/core/firebaseInit';
import { defineStore } from 'pinia';
import { useUserStore } from '@/store/useUser';
import { useChangeStore } from '@/store/useChange';

export const useRegistrationStore = defineStore('registration', {
  state: () => ({
    registration: query(collection(db, 'registrations')),
    user: useUserStore(),
    change: useChangeStore(),
    requestItems: [],
    loading: true,
    error: null,
    requestItem: null,
  }),

  getters: {
    newRequest: (state) => {
      if (state.requestItems.length > 0) {
        return state.requestItems.filter((x) => x.approvals.globalStatus == 'New').length;
      }
      return 0;
    },
  },

  actions: {
    async fetchRequestItems() {
      this.loading = true;
      this.error = '';
      try {
        const registrationQuery = query(this.registration, orderBy('updatedAt', 'desc'));
        const registrations = await onSnapshot(registrationQuery, (querySnapshot) => {
          const rgts = [];
          querySnapshot.forEach((doc) => {
            const reg = { id: doc.id, ...(doc.data() as object) };
            rgts.push(reg);
          });
          this.requestItems = rgts;
          this.loading = false;
        });
        return registrations;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },

    async addNotes(registrationId, note) {
      this.error = '';
      try {
        await setDoc(
          this.setRegistrationDocType('registrations', registrationId),
          {
            notes: note,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );
      } catch (error) {
        this.error = error;
      }
    },

    async dynamicInsert(registrationId, key, value) {
      this.error = '';
      const obj = {};
      try {
        this.loading = true;
        obj[key] = value;
        await setDoc(
          this.setRegistrationDocType('registrations', registrationId),
          {
            ...obj,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },

    async transactionRequest(docId, status, id, decision, reason) {
      this.error = '';
      const sta = status == 'change' ? 'changes-requests' : 'registrations';
      try {
        await setDoc(
          this.setRegistrationDocType(sta, docId),
          this.selections(id, decision, reason),
          { merge: true },
        );
        status == 'change' ? this.change.fetchRequestItemChanges() : this.fetchRequestItems();
      } catch (error) {
        this.error = error;
      }
    },

    selections(keyField, decisionData, reasonData) {
      const obj = {};
      const defaultData = {
        status: decisionData,
        reason: reasonData,
        reviewedBy: this.user.currentUser.email,
        reviewedDate: serverTimestamp(),
      };

      obj[keyField] = defaultData;

      const finalSectionStatus = {
        approvals: {
          globalStatus: 'In Review',
          ...obj,
        },
      };

      return finalSectionStatus;
    },

    async getRequestItem(registrationId) {
      this.loading = true;
      this.error = '';
      try {
        return await onSnapshot(this.setRegistrationDocType('registrations', registrationId), (doc) => {
          this.requestItem = { id: doc.id, ...doc.data() };
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        this.error = error;
      }
    },

    async deleteRequestItem(registrationId) {
      this.loading = true;
      this.error = '';
      try {
        await setDoc(
          this.setRegistrationDocType('registrations', registrationId),
          {
            approvals: { globalStatus: 'deleted' },
            deletedBy: this.user.currentUser.email,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );
      } catch (error) {
        this.error = error;
      }
    },

    setRegistrationDocType(collection, registrationId) {
      return doc(db, collection, registrationId);
    },
  },
});
