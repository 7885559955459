export default {
  accountNumber: '',
  amount: 0,
  couponNumber: '',
  date: undefined,
  id: '',
  state: '',
  source: '',
  type: '',
  url: '',
  importResult: {
    couponNumber: { success: true, message: '', url: '' },
    success: true,
    message: '',
    stack: '',
  },
};
