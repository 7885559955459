import { reactive } from 'vue'

export const drawer = reactive({
    lis: [
        { icon:'mdi-scan-helper', title: 'Scanner', navigation: 'CouponEntry', isNavigation: true, badge: null },
        { icon:'mdi-ticket-percent', title: 'Listes de coupons', navigation: 'CouponLists', isNavigation: true, badge: null },
        { icon:'mdi-ticket-account', title: 'Listes de comptes', navigation: 'AccountLists', isNavigation: true, badge: null },
        { icon:'mdi-account-question', title: 'Nouvelles demandes', navigation: 'OnBoardingRequests', isNavigation: true, badge: 'newRequest' },
        { icon:'mdi-update', title: 'Mises à jour',  navigation: 'OnBoardingRequestsChanges', isNavigation: true , badge: 'newRequestChange' },
        { icon:'mdi-logout', title: 'Se déconnecter', navigation: '', isNavigation: false, badge: null  },
    ],
    open: false
});