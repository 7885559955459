
import { defineComponent, toRef } from 'vue'

export default defineComponent({
  props: {
    isLoading : {
      type: Boolean,
      required: true,
      default: true
    }
  },

  setup(props) {
    const loading = toRef(props, 'isLoading');

    return {
      loading
    }
  }
})
