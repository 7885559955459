import { defineStore } from 'pinia';
import 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
import { func } from '@/core/firebaseInit';

export const useFunctionStore = defineStore('func', {
  actions: {
    async lookupPoket(value) {
      const lookup = await httpsCallable(func, 'odoo-lookupPocket');
      const result = await lookup({ pocket: value });
      return result;
    },
    async importRegistrationInOdoo(registrationId) {
      const importInOdoo = await httpsCallable(func, 'onBoarding-requestDriverRegistrationInOdoo');
      const result = await importInOdoo({ registrationId });
      return result;
    },
  },
});
