import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    color: $props.color,
    location: $props.location,
    elevation: "24",
    modelValue: $setup.open,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.open) = $event))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        color: "white",
        variant: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.open = false))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("mdi-close")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString($props.message) + " ", 1)
    ]),
    _: 1
  }, 8, ["color", "location", "modelValue"]))
}