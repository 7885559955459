export default [
  // { id: 'scanner-a', label: 'Développement (tests)', firebaseDir: 'scanner-a' },
  {
    id: 'scanner1',
    label: 'Scanner 1',
    firebaseDir: 'scanner1',
    cardClass: 'background__green',
    type: 'scanner',
    count_new_status: 0,
    count_error_status: 0,
    description: "Numérisation à l'aide du Scanner 1",
  },
  {
    id: 'scanner2',
    label: 'Scanner 2',
    firebaseDir: 'scanner2',
    cardClass: 'background__blue',
    type: 'scanner',
    count_new_status: 0,
    count_error_status: 0,
    description: "Numérisation à l'aide du Scanner 2",
  },
  {
    id: 'scanner3',
    label: 'Scanner 3',
    firebaseDir: 'scanner3',
    cardClass: 'background__red',
    type: 'scanner',
    count_new_status: 0,
    count_error_status: 0,
    description: "Numérisation à l'aide du Scanner 3",
  },
  {
    id: 'app-membre',
    label: 'App SAM',
    firebaseDir: 'app-membre',
    isAppMembre: true,
    cardClass: 'background__validation',
    type: 'validation',
    count_new_status: 0,
    count_error_status: 0,
    description: 'Coupons soumis par les chauffeurs',
  },
].filter((s) => !s.notForProd || process.env.NODE_ENV !== 'production');
