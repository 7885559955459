
import { defineComponent, ref, computed, PropType } from 'vue';
import { Registration } from '@/models/registration.model';
import { ColumnSubField } from '@/models/utils.model';

export default defineComponent({
  props: {
    columnField: {
      type: String,
      required: true,
      default: '',
    },
    columnSubField: {
      type: Array as PropType<ColumnSubField[]>,
      required: true,
      default: () => [],
    },
    itemData: {
      type: Object as PropType<Registration>,
      required: true,
      default: () => { return {}},
    },
    requestType: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup(props) {
    const toggler = ref(false);
    const color = ref('yellow');

    const approvals = computed(() => {
      return props.itemData.approvals[props.columnField] || {};
    });

    const submitted = computed(() => {
      return props.itemData.submitted[props.columnField] || [];
    });

    return {
      toggler,
      color,
      approvals,
      submitted,
    };
  },
});
