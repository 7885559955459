import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_erp_url = _resolveComponent("erp-url")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.processed)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          border: "start",
          density: "compact",
          variant: "tonal",
          type: $props.item.state === 'approved' ? 'success' : 'error'
        }, {
          default: _withCtx(() => [
            ($props.item.state === 'approved')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", null, "Identifiant Odoo: " + _toDisplayString($props.item.result?.odooId || ''), 1),
                  _createVNode(_component_erp_url, {
                    url: $props.item.result?.url,
                    "odoo-id": $props.item.result?.odooId
                  }, null, 8, ["url", "odoo-id"])
                ]))
              : ($props.item.state === 'rejected')
                ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                    _createTextVNode(" Raison du refus: " + _toDisplayString($setup.declineReason) + " ", 1),
                    ($props.item.approvals.reason === 'other')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, " - " + _toDisplayString($props.item.approvals.custom), 1))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["type"]))
      : _createCommentVNode("", true),
    ($setup.showError)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 1,
          border: "start",
          density: "compact",
          variant: "tonal",
          type: "error"
        }, {
          default: _withCtx(() => [
            (!$setup.couponResult.success)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("p", null, _toDisplayString($setup.couponErrors[$setup.couponResult.message] || $setup.couponResult.message), 1),
                  _createVNode(_component_erp_url, {
                    url: $setup.couponResult.url
                  }, null, 8, ["url"])
                ]))
              : _createCommentVNode("", true),
            (!$setup.accountResult.success)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.couponErrors[$setup.accountResult.message] || $setup.accountResult.message), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.errorMessages, (message, index) => {
              return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString($setup.couponErrors[message] || message), 1))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}