
import { defineComponent, onMounted, ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useChangeStore } from '@/store/useChange';

export default defineComponent({
  setup() {
    const registration = useChangeStore();
    const { requestItemsChange } = storeToRefs(registration);
    const { fetchRequestItemChanges, deleteRequestItem } = useChangeStore();
    const unsubChanges = ref(null);

    onMounted(async () => {
      unsubChanges.value = await fetchRequestItemChanges();
    });

    onUnmounted(async () => {
      await unsubChanges.value();
    });

    return {
      requestItemsChange,
      fetchRequestItemChanges,
      deleteRequestItem
    };
  },
});
