
import { onMounted, computed, ref, watch } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    options: Object,
    styleOptions: Object,
    rules: Object,
    currency: {
      type: String,
      required: false,
      default: '$',
    },
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options);

    const variant = ref(null);
    const density = ref(null);

    const hideDetails = computed(() => {
      return props.styleOptions.hideDetails;
    });
    const isDisabled = computed(() => {
      return props.styleOptions.disabled;
    });

    onMounted(() => {
      const styleOptions = { ...props.styleOptions };
      variant.value = styleOptions.variant;
      density.value = styleOptions.density;
    });

    watch(
      () => props.modelValue,
      (value) => {
        setValue(value)
      }
    )

    return { variant, hideDetails, density, isDisabled, inputRef };
  },
};
