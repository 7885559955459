
import { defineComponent, toRef, computed } from 'vue';
import reasonsToRefuse from '@/utils/request-reasons-to-refuse';
import { useRegistrationStore } from '@/store/useRegistration';

export default defineComponent({
  props: {
    section: {
      type: Object,
    },
    id: {
      type: String,
      required: true,
      default: '',
    },
    docId: {
      type: String,
      required: true,
      default: '',
    },
    requestType: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup(props) {
    const { transactionRequest } = useRegistrationStore();
    const section = toRef(props, 'section');
    const status = computed(() => {
      return section.value && section.value.status
        ? section.value.status.toLowerCase() : 'new';
    });
    const statusText = computed(() => {
      if (status.value === 'approved') return 'Approuvé';
      if (status.value === 'disapproved') return 'Décliner';
      return status.value;
    });
    const declineReason = computed(() => {
      if (status.value && status.value === 'disapproved') {
        return reasonsToRefuse[0][props.id].find((reason) => reason.code === section.value.reason);
      }
      return '';
    });
    const getDecisionColor = computed(() => {
      return status.value == 'approved';
    });
    const isButtonDisabled = computed(() => {
      return status.value == 'approved';
    });

    return {
      status,
      statusText,
      declineReason,
      reasonsToRefuse,
      isButtonDisabled,
      getDecisionColor,
      transactionRequest,
    };
  },
});
