import { useUserStore } from "@/store/useUser";
import { onAuthStateChanged } from 'firebase/auth';

import { auth } from "./firebaseInit";

const getCurrentUser = () => new Promise((resolve, reject) => {
  const removeListener = onAuthStateChanged(
    auth,
    (user) => {
      removeListener();
      resolve(user);
    },
    reject,
  );
});

  /* eslint-disable */
export default (router: any) => {
   router.beforeEach (async(to:any, from:any, next:any) => {
      const auth = useUserStore();
      const user = await getCurrentUser();

      auth.setAuth(user, user ? true : false);  

      if(to.matched.some((record: any) => record.meta.requiresAuth)) {
          if(!auth.isLoggedIn) {
            next({name: 'Login'});
          } else {
            next();
          }
        } else if(to.matched.some((record: any) => record.meta.requiresVisitor)) {
          if(!auth.isLoggedIn) {
              next();
            } else {
              next({name: 'Home'});
            }
        } else {
          next();
        }
      });
}
