import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.loading,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
    "hide-overlay": "",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { color: "primary" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createTextVNode(" Veuillez patienter "),
              _createVNode(_component_v_progress_linear, {
                indeterminate: "",
                color: "white",
                class: "mb-0"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}