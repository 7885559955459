
import { defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useCouponStore } from '@/store/useCoupon';
import { table } from '@/store/stateless/tables';

export default defineComponent({
  setup() {
    const coupon = useCouponStore();
    const { currentListView, accountLength, accountCurrentPage, loading } =
      storeToRefs(coupon);
    const { nextPage, prevPage, setListModal } = useCouponStore();

    const viewDetails = (e) => {
      setListModal(e);
    };

    return {
      setListModal,
      currentListView,
      accountCurrentPage,
      accountLength,
      loading,
      table,
      nextPage,
      prevPage,
      viewDetails,
    };
  },
});
