import {
  collection,
  onSnapshot,
  orderBy,
  query,
  doc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '@/core/firebaseInit';
import { useUserStore } from '@/store/useUser';
import { defineStore } from 'pinia';

export const useChangeStore = defineStore('change', {
  state: () => ({
    change: query(collection(db, 'changes-requests')),
    user: useUserStore(),
    requestItemsChange: [],
    loading: false,
    error: null,
  }),

  getters: {
    newRequestChange: (state) => {
      if (state.requestItemsChange && state.requestItemsChange.length > 0) {
        return state.requestItemsChange.filter(
          (x) => x.approvals && x.approvals.globalStatus == 'New',
        ).length;
      }
      return 0;
    },
  },

  actions: {
    async fetchRequestItemChanges() {
      this.loading = true;
      this.error = '';
      try {
        const registrationQuery = query(this.change, orderBy('updatedAt', 'desc'));
        const changes = await onSnapshot(registrationQuery, (querySnapshot) => {
          const rgts = [];
          querySnapshot.forEach((doc) => {
            const reg = { id: doc.id, ...(doc.data() as object) };
            rgts.push(reg);
          });
          this.requestItemsChange = rgts;
          this.loading = false;
        });
        return changes;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },

    async deleteRequestItem(changeId) {
      this.error = '';
      try {
        await setDoc(
          this.setChangeDocType('changes-requests', changeId),
          {
            approvals: {
              globalStatus: 'deleted',
            },
            deletedBy: this.user.currentUser.email,
            updatedAt: serverTimestamp(),
          },
          { merge: true },
        );
      } catch (error) {
        this.error = error;
      }
    },

    setChangeDocType(collection, changeId) {
      return doc(db, collection, changeId);
    },
  },
});
