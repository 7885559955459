
import { toRef, computed, onMounted } from 'vue';
import couponErrors from '@/utils/coupon-errors';
import reasonsToRefuse from '@/utils/coupons-reasons-to-refuse';

export default {
  name: 'coupon-status',
  props: {
    processed: {
      required: true,
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
    },
    importError: {
      required: true,
      type: Object,
    },
    validateCouponError: {
      required: false,
      type: Object,
    },
  },
  setup(props) {
    const importError = toRef(props, 'importError');
    const validateError = toRef(props, 'validateCouponError');

    const declineReason = computed(() => {
      return reasonsToRefuse
        .find((reasons) =>
          reasons.code === props.item.approvals.reason)
        .label || '';
    });

    const showError = computed(() => {
      const importSuccess = importError.value ? importError.value.success : true;
      const validateSuccess = validateError.value ? validateError.value.success : true;
      return !(importSuccess && validateSuccess);
    });
    const couponResult = computed(() => {
      return validateError.value ? validateError.value.couponResult : { success: true };
    });
    const accountResult = computed(() => {
      return validateError.value ? validateError.value.accountResult : { success: true };
    });

    onMounted(async () => {
      // validate();
    });

    const errorMessages = computed(() => {
      return importError.value
        ? importError.value.message
            .split('\\n')
            .map((line) => line.charAt(0).toUpperCase() + line.substring(1))
        : [];
    });

    return {
      errorMessages,
      validateError,
      couponResult,
      accountResult,
      showError,
      couponErrors,
      declineReason
    };
  },
};
