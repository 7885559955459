import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        align: "center",
        justify: "space-between"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                to: `/on-boarding-requests/${_ctx.type}/${_ctx.previousItemId}`,
                disabled: _ctx.isFirstItem
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Retour ")
                ]),
                _: 1
              }, 8, ["to", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "6" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_v_btn, {
                  to: `/on-boarding-requests/${_ctx.type}/${_ctx.nextItemId}`,
                  disabled: _ctx.isLastItem
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Suivant ")
                  ]),
                  _: 1
                }, 8, ["to", "disabled"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}