import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LandingView from '../views/LandingView.vue';
import CouponView from '../views/Coupon/CouponView.vue';
import ScannerView from '../views/Coupon/ScannerView.vue';
import RequestView from '../views/RequestView.vue';
import UpdateView from '../views/UpdateView.vue';
import OnBoardingView from '../views/OnBoardingView.vue';
import OnBoardingChangeView from '../views/OnBoardingChangeView.vue';
import CouponLists from '../views/CouponLists.vue';
import AccountLists from '../views/AccountLists.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/views/LoginView.vue'),
    name: 'Login',
    meta: {
      requiresVisitor: true
    },
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/coupon-entry',
    component: LandingView,
    children: [
      { path: 'coupon-entry', name: 'CouponEntry', component: CouponView },
      { path: 'coupon-lists', name: 'CouponLists', component: CouponLists },
      { path: 'account-lists', name: 'AccountLists', component: AccountLists },
      { path: 'coupon-entry/scanner/:id', name: 'Scanner', component: ScannerView },
      { path: 'on-boarding-requests', name: 'OnBoardingRequests', component: RequestView },
      { path: 'on-boarding-requests/request/:id', name: 'OnBoardingRequest', component: OnBoardingView },
      { path: 'on-boarding-requests-changes', name: 'OnBoardingRequestsChanges', component: UpdateView },
      { path: 'on-boarding-requests/request-change/:id', name: 'OnBoardingRequestsChange', component: OnBoardingChangeView },
    ],
    meta: {
      requiresAuth: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router
