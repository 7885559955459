
import { Coupon, BatchData } from '@/models/coupon.model';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';
import { rules } from '@/store/stateless/rules';
import { storeToRefs } from 'pinia';
import { useCouponImageStore } from '@/store/useCouponImage';
import { useCouponStore } from '@/store/useCoupon';

export default defineComponent({
  name: 'one-coupon',
  props: {
    item: {
      required: true,
      type: Object as PropType<Coupon>,
    },
    batchData: {
      type: Object as PropType<BatchData>,
      default: {} as BatchData,
    },
    batchId: {
      type: String,
    }
  },
  setup(props) {
    const imageStore = useCouponImageStore();
    const { rotation } = storeToRefs(imageStore);
    const { imageCrop } = useCouponImageStore();
    const coupon = useCouponStore();
    const { saveData, couponsOnCall } = useCouponStore();

    const { currentScanner } = storeToRefs(coupon);

    const item = toRef(props, 'item');
    const batchData = toRef(props, 'batchData');

    const updateUrl = ref(null);
    const version = ref(0);
    const cropping = ref(false);
    const processCRO = ref(false);
    const couponImage = ref(null);
    const loading = ref(false);
    const loadingError = ref(false);
    const couponFields = ref(null);
    const valid = ref(false);
    const resultValidateCoupon = ref();
    const isOthers = ref(false);
    const customReason = ref('');

    const transferElement = (element) => {
      [element.object.type] = element.text;
    };

    const vehicleSelected = computed(() => {
      if (!currentScanner.value.isAppMembre) {
        return batchData.value.batchId;
      }

      return true;
    });

    const isProcessed = computed(() => {
      return item.value.state === 'rejected'
        || (item.value.state === 'approved' && item.value.importState === 'odoo-import-success');
    });

    const augmented = computed(() => {
      const item = { ...props.item };
      if (updateUrl.value) {
        item.url = updateUrl.value;
      }
      return item;
    });

    const cropperClosed = (url) => {
      cropping.value = false;
      version.value += 1;
      if (typeof url === 'string') {
        updateUrl.value = url;
        item.value.url = url;
      }
    };

    const saveRotation = (x) => {
      rotation.value = x;
    };

    const approve = async () => {
      loading.value = true;
      if (
        couponFields.value.type === 'CHO' &&
        couponFields.value.accountNumber.toString().at(0) !== 'H'
      ) {
        couponFields.value.accountNumber = `H${couponFields.value.accountNumber}`;
      }

      if (valid.value) {
        try {
          const result = await couponsOnCall(couponFields.value, 'coupons-validateCoupon');
          resultValidateCoupon.value = result;
          if (resultValidateCoupon.value.success) {
            const payload = {
              ...couponFields.value,
              importState: '',
            };
            await saveData(
              item.value.id,
              'approved',
              '',
              couponImage.value,
              payload,
              customReason.value,
            );
          }
        } catch (error) {
          console.log(error);
        }
      }

      loading.value = false;
      return null;
    };

    const refuse = async (reasonCode) => {
      if (reasonCode === 'other') {
        isOthers.value = true;
      } else {
        await handleRefuse(reasonCode);
      }
    };

    const handleRefuse = async (reasonCode) => {
      loadingError.value = true;
      try {
        if (isOthers.value === true) {
          if (customReason.value.length > 30) {
            throw 'Le motif de rejet personnalisé est obligatoire et ne doit pas comporter plus de 30 caractères';
          } else if (customReason.value.length === 0) {
            throw 'Ce champ est obligatoire';
          }
        }
        const payload = item.value.importState ? { importState: '' } : {};
        await saveData(
          item.value.id,
          'rejected',
          reasonCode,
          couponImage.value,
          { ...payload },
          customReason.value,
        );
        isOthers.value = false;
      } catch (error) {
        console.log(error);
      }
      loadingError.value = false;
    };

    const getValue = () => {
      const couponNumber =
        item.value.type === 'ECR'
          ? `${item.value.couponNumber}-${item.value.accountNumber}`
          : item.value.couponNumber;
      const accountNumber =
        item.value.type === 'CHO' ? `H${item.value.accountNumber}` : item.value.accountNumber;

      return {
        type: item.value.type,
        couponNumber,
        accountNumber,
        amount: item.value.amount,
        invalid: !valid.value,
        imageFileName: item.value.url,
      };
    };

    const processImage = async () => {
      try {
        const result = await imageCrop(item.value.id);
        if (result) processCRO.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      processImage,
      customReason,
      processCRO,
      isOthers,
      version,
      augmented,
      updateUrl,
      loading,
      loadingError,
      couponImage,
      currentScanner,
      cropping,
      rotation,
      valid,
      couponFields,
      resultValidateCoupon,
      vehicleSelected,
      isProcessed,
      cropperClosed,
      transferElement,
      getValue,
      approve,
      refuse,
      saveRotation,
      handleRefuse,
      rules,
    };
  },
});
