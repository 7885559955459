
import { defineComponent, toRef } from 'vue';
import router from '@/router';
/* eslint-disable */
export default defineComponent({
  props: {
    items: {
      type: Array,
      required: true,
      default: [] as Array<any>,
    },
    isChange: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const isChange = toRef(props, 'isChange');

    const onRowClick = (event) => {
      if (!isChange.value) {
        router.push({ name: 'OnBoardingRequest', params: { id: event } });
      } else {
        router.push({ name: 'OnBoardingRequestsChange', params: { id: event } });
      }
    };

    return {
      onRowClick,
    };
  },
});
