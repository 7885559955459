import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: index,
        onClick: ($event: any) => (_ctx.$emit('openDialog', item))
      }, [
        _createElementVNode("td", null, _toDisplayString(item.accountNumber ? item.accountNumber : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.couponNumber ? item.couponNumber : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.source ? item.source : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.state ? item.state : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.type ? item.type : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.state != 'new' && item.approvals ?  item.approvals.status : '-'), 1),
        _createElementVNode("td", null, _toDisplayString(item.state != 'new' && item.importResult ?  item.importResult.success : '-'), 1)
      ], 8, _hoisted_1))
    }), 128))
  ]))
}