
export default {
  name: 'coupon-statistics',
  props: {
    visible: {
      required: false,
      type: Boolean,
      default: true,
    },
    disableReport: {
      required: false,
      type: Boolean,
      default: true,
    },
    enableClear: {
      required: false,
      type: Boolean,
      default: false,
    },
    processedTotal: {
      required: true,
      type: Object,
    },
    unprocessedTotal: {
      required: true,
      type: Object,
    },
  }
};
