import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_chip = _resolveComponent("v-chip")!

  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (item.approvals && item.approvals.globalStatus !== 'deleted')
          ? (_openBlock(), _createElementBlock("tr", {
              key: 0,
              onClick: ($event: any) => (_ctx.onRowClick(item.id)),
              style: {"cursor":"pointer"}
            }, [
              _createElementVNode("td", null, _toDisplayString(item.submitted && item.submitted.profil.givenName
              ? item.submitted.profil.givenName
              : 'Aucun'), 1),
              _createElementVNode("td", null, _toDisplayString(item.submitted && item.submitted.profil.lastName
              ? item.submitted.profil.lastName
              : 'Aucun'), 1),
              _createElementVNode("td", null, _toDisplayString(item.submitted && item.submitted.profil.email ? item.submitted.profil.email : 'Aucun'), 1),
              _createElementVNode("td", null, _toDisplayString(item.submitted && item.submitted.profil.phoneNumber
              ? item.submitted.profil.phoneNumber
              : 'Aucun'), 1),
              _createElementVNode("td", null, [
                (item.approvals)
                  ? (_openBlock(), _createBlock(_component_v_chip, {
                      key: 0,
                      class: "ma-2",
                      close: "",
                      color: 
              item.approvals.globalStatus == 'Approved'
                ? 'success'
                : item.approvals.globalStatus == 'New'
                ? 'primary'
                : 'warning'
            ,
                      label: "",
                      "text-color": "white"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.approvals.globalStatus ? item.approvals.globalStatus : 'Aucun'), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_1))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}