import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "ma-2" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass([{
              'bg-blue': _ctx.status == 'New',
              'bg-purple': _ctx.status == 'In Review',
              'bg-green': _ctx.status == 'Approved',
              'bg-red': _ctx.status == 'deleted'
            }, "text-capitalize text-white pa-1 rounded"])
              }, _toDisplayString(_ctx.status), 3)
            ]),
            _createVNode(_component_v_avatar, {
              color: "grey",
              size: "250",
              rounded: "50"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  cover: "",
                  src: _ctx.imageSrc,
                  alt: _ctx.name,
                  style: {"position":"relative","width":"250px","height":"250px","overflow":"hidden","border-radius":"50%"}
                }, null, 8, _hoisted_3)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}