
import { defineComponent, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCouponStore } from '@/store/useCoupon';
import { table } from '@/store/stateless/tables';

export default defineComponent({
  setup() {
    const coupon = useCouponStore();
    const { paginatedCoupons, loading, search, disableNextButton, disablePrevButton } =
      storeToRefs(coupon);
    const { fetchPaginatedCoupons, nextPage, prevPage, setListModal } = useCouponStore();

    onMounted(async () => {
      await fetchPaginatedCoupons();
    });

    const viewDetails = (e) => {
      setListModal(e);
    };

    return {
      setListModal,
      disableNextButton,
      disablePrevButton,
      paginatedCoupons,
      loading,
      search,
      table,
      fetchPaginatedCoupons,
      nextPage,
      prevPage,
      viewDetails,
    };
  },
});
