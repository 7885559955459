import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_list = _resolveComponent("modal-list")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_item_account_lists = _resolveComponent("item-account-lists")!
  const _component_samva_table = _resolveComponent("samva-table")!
  const _component_v_pagination = _resolveComponent("v-pagination")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_modal_list),
      _createVNode(_component_v_row, { class: "justify-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            md: "10"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_title, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "justify-space-between align-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Listes de comptes ")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_v_col, {
                            cols: "12",
                            md: "5"
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", null, [
                    _createVNode(_component_samva_table, {
                      tableName: _ctx.table.accountLists,
                      items: _ctx.currentListView,
                      loading: _ctx.loading
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_item_account_lists, { items: _ctx.currentListView }, null, 8, ["items"])
                      ]),
                      _: 1
                    }, 8, ["tableName", "items", "loading"]),
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_v_container, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, { justify: "center" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, { cols: "8" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_container, { class: "max-width" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_pagination, {
                                        modelValue: _ctx.accountCurrentPage,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.accountCurrentPage) = $event)),
                                        length: _ctx.accountLength
                                      }, null, 8, ["modelValue", "length"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}