import { Coupon, CouponApprovals } from '@/models/coupon.model';
import { Scanner } from '@/models/scanner.model';
import { defineStore } from 'pinia';
import cloneDeep from 'clone-deep';
import { nanoid } from 'nanoid';
import {
  collection,
  query,
  where,
  onSnapshot,
  limit,
  doc,
  setDoc,
  orderBy,
  getDoc,
  getDocs,
  deleteDoc,
  startAfter,
  endBefore,
  limitToLast,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, func } from '@/core/firebaseInit';
import scanners from '@/utils/scanners.js';
import couponObject from '@/utils/coupon.js';
import _ from 'lodash';
import { useUserStore } from '@/store/useUser';

import { reactive } from 'vue';

export const useCouponStore = defineStore('coupon', {
  state: () => ({
    coupon: query(collection(db, 'coupons')),
    user: useUserStore(),
    loading: false,
    scanners,
    currentScanner: {} as Scanner,
    error: '',
    isError: false,
    scannersCoupons: reactive([]),
    scannersCouponsErrors: reactive([]),
    couponImage: null,
    timing: 0,
    showReport: false,
    isValid: true,
    processedCount: 0,
    openImport: false,
    enableClear: false,
    paginatedCoupons: [],
    currentPage: {},
    lastVisible: {},
    prevVisible: {},
    search: '',
    order: 'date',
    perPage: 100,
    listCoupon: {},
    listCouponOpen: false,
    listCounter: 0,
    openFilter: false,
    selectedCoupon: [],
    refreshTime: 0,
    listAccounts: [],
    accountPageSize: 50,
    accountCurrentPage: 1,
  }),

  getters: {
    getErrorResponse: () => {
      return 'error';
    },

    disableNextButton: (state) => {
      if (!state.lastVisible) return true;
      if (state.paginatedCoupons.length < state.perPage) return true;
      if (typeof state.lastVisible === 'object' && Object.keys(state.lastVisible).length === 0)
        return true;

      return false;
    },

    disablePrevButton: (state) => {
      if (!state.prevVisible) return true;
      if (typeof state.prevVisible === 'object' && Object.keys(state.prevVisible).length === 0)
        return true;
      if (state.listCounter === 0) return true;

      return false;
    },

    indexStart: (state) => {
      return (state.accountCurrentPage - 1) * state.accountPageSize;
    },

    indexEnd(state): number {
      return this.indexStart + state.accountPageSize;
    },

    currentListView(state) {
      return state.listAccounts.slice(this.indexStart, this.indexEnd);
    },

    accountLength: (state) => {
      const accSize = state.listAccounts.length / state.accountPageSize;
      return Number.isInteger(accSize) ? accSize : parseInt(accSize.toString()) + 1;
    },
  },

  actions: {
    async fetchPaginatedCoupons(search = '') {
      try {
        this.loading = true;
        this.currentPage = query(this.coupon, orderBy(this.order, 'desc'), limit(this.perPage));
        if (search) this.currentPage = query(this.currentPage, where('couponNumber', '==', search));

        const snapShots = await getDocs(this.currentPage);

        this.setPaginatedCouponValue(snapShots);

        this.lastVisible = snapShots.docs[snapShots.docs.length - 1];

        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },

    async nextPage() {
      try {
        this.loading = true;
        this.currentPage = query(
          this.coupon,
          orderBy(this.order, 'desc'),
          startAfter(this.lastVisible),
          limit(this.perPage),
        );
        if (this.search)
          this.currentPage = query(this.currentPage, where('couponNumber', '==', this.search));

        const snapShots = await getDocs(this.currentPage);

        this.setPaginatedCouponValue(snapShots);

        this.loading = false;

        this.lastVisible = snapShots.docs[snapShots.docs.length - 1];
        this.prevVisible = snapShots.docs[0];
        this.listCounter++;
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      }
    },

    async prevPage() {
      try {
        this.loading = true;

        this.currentPage = query(
          this.coupon,
          orderBy(this.order, 'desc'),
          endBefore(this.prevVisible),
          limitToLast(this.perPage),
        );
        if (this.search)
          this.currentPage = query(this.currentPage, where('couponNumber', '==', this.search));

        const snapShots = await getDocs(this.currentPage);

        this.setPaginatedCouponValue(snapShots);

        this.loading = false;

        this.lastVisible = snapShots.docs[snapShots.docs.length - 1];
        this.prevVisible = snapShots.docs[0];
        this.listCounter--;
        window.scrollTo(0, 0);
      } catch (error) {
        console.log(error);
      }
    },

    setPaginatedCouponValue(snapShots) {
      const cns = [];
      snapShots.forEach((doc) => {
        const coupon = { id: doc.id, ...(doc.data() as object) };
        cns.push(coupon);
      });
      this.paginatedCoupons = cns;
    },

    async couponsOnCall(value, type) {
      try {
        this.setError('', false);
        this.loading = true;

        const onCall = httpsCallable(func, type);
        const result = await onCall({ data: value });
        const data = result.data;
        this.loading = false;
        return data;
      } catch (error) {
        this.setError(error);
        this.loading = false;
        return error;
      }
    },

    async fetchCoupons() {
      try {
        this.setError('', false);
        this.loading = true;
        return onSnapshot(query(this.coupon, where('state', '==', 'new')), (querySnapshot) => {
          const cns = [];
          this.newItems = 0;
          this.scannerIndex = 0;
          this.scanner = null;

          querySnapshot.forEach((doc) => {
            const coupon = { id: doc.id, ...(doc.data() as object) };
            cns.push(coupon);
          });

          let key = null;
          for (key in this.scanners) {
            this.scanner = this.scanners[key].id;
            this.newItems = _.countBy(cns, { source: this.scanner }).true;

            this.scannerIndex = this.scanners.findIndex((obj) => obj.id === this.scanner);
            this.scanners[this.scannerIndex].count_new_status = this.newItems;
          }

          this.loading = false;
        });
      } catch (error) {
        this.setError(error);
      }
    },

    async fetchErrorCoupons() {
      try {
        this.setError('', false);
        this.loading = true;
        const q = query(this.coupon,
          where('state', '==', 'approved'),
          where('importState', '!=', 'odoo-import-success'),
          orderBy('importState', 'asc'),
        );
        console.log('fetchErrorCoupons', q);
        return onSnapshot(q, (querySnapshot) => {
          console.log('fetchErrorCoupons', querySnapshot);
          const cns = [];
          this.newItems = 0;
          this.scannerIndex = 0;
          this.scanner = null;

          querySnapshot.forEach((doc) => {
            const coupon = { id: doc.id, ...(doc.data() as object) };
            cns.push(coupon);
          });

          let key = null;
          for (key in this.scanners) {
            this.scanner = this.scanners[key].id;
            this.newItems = _.countBy(cns, { source: this.scanner }).true;

            this.scannerIndex = this.scanners.findIndex((obj) => obj.id === this.scanner);
            this.scanners[this.scannerIndex].count_error_status = this.newItems;
          }

          this.loading = false;
        });
      } catch (error) {
        console.log('Error: ', error);
        this.setError(error);
      }
    },

    // fetchType = new, batch, error
    async fetchCouponType(router_id, fetchType, batchId, couponType) {
      try {
        this.setError('', false);

        this.loading = true;

        const queries = [
          where('source', '==', router_id),
          fetchType === 'new' && where('state', '==', 'new'),

          // fetch coupons with the same batchId
          batchId && where('batchId', '==', batchId),
          fetchType === 'batch' && batchId && where('state', '!=', 'deleted'),
          fetchType === 'batch' && orderBy('state', 'asc'),

          // fetch coupons that wasn't successfully imported to odoo
          fetchType === 'error' && where('state', '==', 'approved'),
          fetchType === 'error' && where('importState', '!=', 'odoo-import-success'),
          fetchType === 'error' && orderBy('importState', 'asc'),

          // fetch coupons that matches couponType filter
          // couponType filter will not be applied when batchId is present
          !batchId && couponType && where('type', 'in', couponType),

          orderBy('date', 'asc'),
          limit(50),
        ].filter((v) => v);

        const q = query(this.coupon, ...queries);

        const docs = await getDocs(q);

        const scn = [];

        docs.forEach((doc) => {
          const scan: Coupon = {
            ...cloneDeep(couponObject),
            id: doc.id,
            ...cloneDeep(doc.data() as object),
            couponNumberLoading: null,
            isActive: null,
          };
          scan.importResult = {
            ...cloneDeep(couponObject).importResult,
            ...scan.importResult,
            ...scan.result,
          };
          scn.push(scan);
        });

        if (fetchType !== 'error') {
          this.scannersCoupons = scn;
        } else {
          this.scannersCouponsErrors = scn;
        }

        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },

    async saveUrl(couponId, dataUrl, originalUrl, title = null) {
      this.loading = true;
      this.setError('', false);
      try {
        const checkFlag = await this.flagCoupon(couponId);
        await setDoc(
          this.setCouponDocType(couponId),
          {
            url: dataUrl,
            originalUrl,
            isModifiedCropRotate: checkFlag && checkFlag.isSelectedCropRotate ? true : false,
          },
          { merge: true },
        );
        this.loading = false;
        this.checkCoupon(couponId, title);
        this.updateCoupon(couponId, { url: dataUrl, originalUrl });
      } catch (error) {
        this.setError(error);
        console.log('Error: ', error);
      }
    },

    async flagCoupon(couponId) {
      const snap = await getDoc(this.setCouponDocType(couponId));
      return snap.data();
    },

    async checkCoupon(couponId, title) {
      const titles = ['Crop Image', 'Rotate Image'];
      this.loading = true;
      this.setError('', false);
      try {
        await setDoc(
          this.setCouponDocType(couponId),
          {
            isSelectedCropRotate: title ? titles.includes(title) : false,
          },
          { merge: true },
        );
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },

    async saveData(couponId, couponStatus, reasonCode, couponImage, payload = {}, customReason) {
      this.loading = true;
      this.setError('', false);
      const approvals: CouponApprovals = {
        reason: reasonCode,
        custom: customReason,
        reviewedBy: this.user.currentUser.email,
        reviewedDate: Date().toLocaleString(),
        status: couponStatus,
      };
      const imageTransforms = {
        rotation: couponImage.getRotation(),
      };
      const state = couponStatus;
      const data = {
        state,
        approvals,
        imageTransforms,
        ...payload,
      };
      try {
        await setDoc(this.setCouponDocType(couponId), data, { merge: true });
        if (couponStatus === 'approved') {
          const result = await this.couponsOnCall(couponId, 'coupons-transformationAndImport');
          this.loading = false;
          this.updateCoupon(couponId, {
            result,
            importResult: result,
            approvals,
            state,
            payload,
            importState: 'odoo-import-success',
          });
        } else {
          this.loading = false;
          this.updateCoupon(couponId, { approvals, state, payload, importState: '' });
        }
      } catch (error) {
        this.setError(error);
        this.loading = false;
        console.log('Error: ', error);
      }
    },

    updateCoupon(couponId, data) {
      const idx = this.scannersCoupons.findIndex((c) => c.id === couponId);
      this.scannersCoupons[idx] = { ...this.scannersCoupons[idx], ...data };
    },

    async generateBatchId(data, unprocessedCoupons: Coupon[]) {
      if (!this.currentScanner.isAppMembre) {
        this.loading = true;
        try {
          const batchId = nanoid(10);
          if (!data.driverId || !data.vehicleId) {
            throw new Error('L\'identification du conducteur et l\'identification du véhicule sont requises.');
          }
          const payload = {
            driverId: data.driverId,
            vehicleId: data.vehicleId,
            batchId: batchId,
          };
          for (const coupon of unprocessedCoupons) {
            await setDoc(this.setCouponDocType(coupon.id), payload, { merge: true });
          }
          await this.setScannerSession(batchId, data);
          return batchId;
        } catch (error) {
          this.setError(error);
          this.loading = false;
          return '';
        }
      }
    },

    async setScannerSession(batchId, data) {
      await setDoc(doc(db, 'coupon-session', this.currentScanner.id), { ...data, batchId });
    },

    async getScannerSession() {
      const session = await getDoc(doc(db, 'coupon-session', this.currentScanner.id));
      return session.data() || {};
    },

    async deleteScannerSession() {
      await deleteDoc(doc(db, 'coupon-session', this.currentScanner.id));
    },

    async invalidate(couponId, odooImportState) {
      this.loading = true;
      this.setError('', false);
      const importState = odooImportState ? { importState: '' } : {};
      try {
        await setDoc(
          this.setCouponDocType(couponId),
          { state: 'deleted', ...importState },
          { merge: true },
        );
        this.removeCoupon(couponId);
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },

    removeCoupon(couponId) {
      const cpnIndex = this.scannersCoupons.findIndex((c) => c.id === couponId);
      const cpnErrIndex = this.scannersCouponsErrors.findIndex((c) => c.id === couponId);
      const cpnSelIndex = this.selectedCoupon.findIndex((c) => c.id === couponId);
      if (cpnIndex >= 0) {
        this.scannersCoupons.splice(cpnIndex, 1);
      }
      if (cpnErrIndex >= 0) {
        this.scannersCouponsErrors.splice(cpnErrIndex, 1);
      }
      if (cpnSelIndex >= 0) {
        this.selectedCoupon.splice(cpnSelIndex, 1);
      }
    },

    async saveField(couponId, param, value) {
      this.setError('', false);
      try {
        await setDoc(
          this.setCouponDocType(couponId),
          {
            [param]: value,
          },
          { merge: true },
        );
      } catch (error) {
        this.setError(error);
      }
    },

    async deletAll(unprocessedCoupons: Coupon[]) {
      for (const coupon of unprocessedCoupons) {
        await this.invalidate(coupon.id, coupon.importState);
      }
    },

    setError(error, status = true) {
      this.error = error;
      this.isError = status;
      this.loading = false;
    },

    fetchScanner(router_id) {
      this.currentScanner = this.scanners.find((s) => s.id == router_id);
    },

    setCouponDocType(couponId) {
      return doc(db, 'coupons', couponId);
    },

    reset() {
      this.openImport = false;
      this.processedCount = 0;
      this.timing = 0;
      this.scannersCoupons = [];
      this.scannersCouponsErrors = [];
    },

    setListModal(value) {
      this.listCoupon = value;
      this.listCouponOpen = true;
    },

    async deleteSetCoupon(coupons: Array<Coupon>) {
      const cpns = [...cloneDeep(coupons)];
      for (const coupon of cpns) {
        await this.invalidate(coupon.id, coupon.importState);
      }
    },
  },
});
