
import { Vehicle, DriverLicenceData } from '@/models/coupon.model';
import { storeToRefs } from 'pinia';
import { useCouponStore } from '@/store/useCoupon';
import router from '@/router';
import accounting from 'accounting';
import { onMounted, ref, defineComponent, computed, onUnmounted, watch } from 'vue';
import { print } from '@/store/stateless/print';
import { Timestamp } from '@firebase/firestore';

export default defineComponent({
  setup() {
    const coupon = useCouponStore();
    const oneCoupon = ref(null);
    const {
      error,
      showReport,
      openImport,
      enableClear,
      timing,
      currentScanner,
      loading,
      scannersCoupons,
      scannersCouponsErrors,
      selectedCoupon,
    } = storeToRefs(coupon);
    const {
      fetchScanner,
      getScannerSession,
      fetchCouponType,
      deletAll,
      deleteSetCoupon,
      generateBatchId,
      reset,
      deleteScannerSession,
      invalidate,
    } = useCouponStore();

    const routerId = ref(router.currentRoute.value.params.id);

    const count = computed(() => {
      return (currentScanner.value.count_new_status || 0) + (currentScanner.value.count_error_status || 0)
    })

    const sortCoupons = (coupons) => {
      return coupons.sort(
        (a, b) =>
          (a.date as Timestamp).toDate().getTime() - (b.date as Timestamp).toDate().getTime(),
      );
    };

    const unprocessedCoupons = computed(() => {
      if (batchId.value) {
        const filtered = scannersCoupons.value.filter((c) => {
          if (c.state === 'new') {
            return true;
          } else if (
            c.state === 'approved' &&
            (c.importState === '' || c.importState === 'odoo-import-error')
          ) {
            return true;
          } else {
            return false;
          }
        });
        return sortCoupons(filtered);
      } else {
        return sortCoupons(scannersCoupons.value.concat(scannersCouponsErrors.value));
      }
    });

    const allItems = computed(() => {
      if (unprocessedCoupons.value) {
        if (currentScanner.value.isAppMembre) {
          return unprocessedCoupons.value.slice(0, 50);
        }

        return scannersCoupons.value.slice(0, 50);
      } else {
        return [];
      }
    });

    const filteredItems = computed(() => {
      return sortCoupons(allItems.value);
    });

    const processedTotal = computed(() => {
      const result = {
        approved: { total: 0, amount: 0 },
        rejected: { total: 0, amount: 0 },
      };

      if (scannersCoupons.value) {
        scannersCoupons.value.reduce((acc, curr) => {
          if (curr.state === 'approved' && curr.importState === 'odoo-import-success') {
            acc.approved.total++;
            acc.approved.amount += curr.amount;
          } else if (curr.state === 'rejected') {
            acc.rejected.total++;
            acc.rejected.amount += curr.amount;
          }
          return acc;
        }, result);
      }
      result.approved.amount = accounting.formatMoney(result.approved.amount, '', 2, ' ', ',');
      result.rejected.amount = accounting.formatMoney(result.rejected.amount, '', 2, ' ', ',');
      return result;
    });

    watch(
      processedTotal,
      (newValue, oldValue) => {
        if (newValue && oldValue) {
          if (
            newValue.approved.total != oldValue.approved.total ||
            newValue.rejected.total != oldValue.rejected.total
          ) {
            if (currentScanner.value.isAppMembre) {
              if (oneCoupon.value) {
                scrollTo();
              }
            } else if (oneCoupon.value && batchId.value) {
              scrollTo();
            }
          }
        }
      },
      { immediate: true },
    );

    const unprocessedTotal = computed(() => {
      const result = { total: 0, amount: 0 };

      if (unprocessedCoupons.value) {
        unprocessedCoupons.value.reduce((acc, curr) => {
          acc.total++;
          acc.amount += curr.amount;
          return acc;
        }, result);
        result.amount = accounting.formatMoney(result.amount, '', 2, ' ', ',');
      }

      return result;
    });

    const driverLicence = ref('');
    const driverLicenceData = ref({} as DriverLicenceData);
    const batchId = ref('');
    const vehicles = ref([] as Array<Vehicle>);
    const vehicleId = ref(null);
    const driverId = ref(null);
    const confirmOpen = ref(false);
    const deleteText = ref({ type: '', title: '', message: '' });
    const deleteData = ref({ id: '', importState: '' });

    const vehicle = computed(() => {
      try {
        return vehicles.value.find(v => v.id === vehicleId.value);
      } catch (error) {
        return null;
      }
    });

    const deleteCallback = async (type) => {
      if (type === 'one') {
        await invalidate(deleteData.value.id, deleteData.value.importState);
      } else if (type === 'selected') {
        await deleteSetCoupon(selectedCoupon.value);
      } else {
        await deletAll(unprocessedCoupons.value);
      }

      if (allItems.value.length === 0) {
        clearScanner();
      }
    };

    const confirmDelete = (type, event) => {
      deleteText.value.type = type;
      if (type === 'one') {
        confirmOpen.value = true;
        deleteText.value.title = 'Supprimer ce coupon';
        deleteText.value.message = 'Êtes-vous sûr de vouloir supprimer ce coupon?';
        deleteData.value = event;
      } else if (type === 'selected') {
        confirmOpen.value = true;
        deleteText.value.title = 'Supprimer les coupons sélectionnés';
        deleteText.value.message = 'Êtes-vous sûr de vouloir supprimer les coupons sélectionnés?';
      } else {
        confirmOpen.value = true;
        deleteText.value.title = 'Supprimer tous les coupons';
        deleteText.value.message = 'Êtes-vous sûr de vouloir supprimer tous les coupons?';
      }
    };

    const onceLookUp = (data) => {
      driverLicenceData.value = data || {};
      vehicles.value = driverLicenceData.value.vehicles || [];
      if (vehicles.value.length === 1) {
        vehicleId.value = vehicles.value[0].id;
      } else {
        vehicleId.value = null;
      }
    };

    const driverLicenceContainer = ref(null);
    const couponStats = ref(null);
    const scVisible = ref(true);
    const couponStatsHeight = ref(0);

    const onScroll = () => {
      if (driverLicenceContainer.value) {
        scVisible.value = driverLicenceContainer.value.$el.getBoundingClientRect().bottom > 0;
      }
    };

    const scrollTo = () => {
      setTimeout(() => {
        const nextCoupon = oneCoupon.value.find((c) => !c.$el.className.includes('processed'));
        if (nextCoupon) {
          couponStatsHeight.value = couponStats.value ? couponStats.value.$el.offsetHeight : 0;
          const offset = !scVisible.value ? couponStatsHeight.value : couponStatsHeight.value * 2;

          const y = nextCoupon.$el.offsetTop - offset;
          window.scroll({ top: y, behavior: 'smooth' });
        }
      }, 3000);
    };

    onMounted(async () => {
      fetchScanner(routerId.value);
      if (!currentScanner.value.isAppMembre) {
        const session = await getScannerSession();
        if (session.batchId) {
          driverLicence.value = session.driverLicence;
          vehicles.value = session.vehicles;
          vehicleId.value = session.vehicleId;
          batchId.value = session.batchId;
          driverId.value = session.driverId;
          fetchCoupons(batchId.value, null);
        }
        document.addEventListener('scroll', onScroll, true);
      }
    });

    const fetchCoupons = async (batch_id, filterType = null) => {
      if (batch_id) {
        await fetchCouponType(routerId.value, 'batch', batch_id, filterType);
      } else {
        await fetchCouponType(routerId.value, 'new', batchId.value, filterType);
        await fetchCouponType(routerId.value, 'error', batchId.value, filterType);
      }
    };

    onUnmounted(async () => {
      selectedCoupon.value = [];
      reset();
      driverLicenceData.value = {} as DriverLicenceData;
      if (!currentScanner.value.isAppMembre) {
        document.removeEventListener('scroll', onScroll, true);
      }
    });

    const applyToAll = async (data) => {
      batchId.value = await generateBatchId(data, unprocessedCoupons.value);
      if (batchId.value) {
        fetchCoupons(batchId.value);
      }
    };

    const completeImport = async () => {
      openImport.value = true;
    };

    const clearScanner = async () => {
      reset();
      enableClear.value = false;
      driverLicenceData.value = {} as DriverLicenceData;
      batchId.value = '';
      driverLicence.value = '';
      vehicles.value = [];
      vehicleId.value = null;
      await deleteScannerSession();
    };

    return {
      completeImport,
      selectedCoupon,
      print,
      processedTotal,
      unprocessedTotal,
      oneCoupon,
      driverLicence,
      vehicle,
      vehicles,
      vehicleId,
      driverId,
      count,
      error,
      timing,
      showReport,
      unprocessedCoupons,
      scannersCoupons,
      filteredItems,
      scVisible,
      driverLicenceContainer,
      currentScanner,
      loading,
      driverLicenceData,
      openImport,
      enableClear,
      confirmOpen,
      deleteText,
      batchId,
      couponStats,
      couponStatsHeight,
      deleteCallback,
      confirmDelete,
      clearScanner,
      onceLookUp,
      applyToAll,
      fetchCoupons,
    };
  },
});
