
import { defineComponent, ref, watchEffect, toRef } from 'vue';
import requestProfile from '@/utils/request-profile';
import requestSections from '@/utils/request-sections';
import { table } from '@/store/stateless/tables';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    isType: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const currentTab = ref('profil');
    const profileImage = ref(null);
    const items = toRef(props, 'item');

    watchEffect(() => {
      profileImage.value =
        items.value &&
        items.value.submitted &&
        items.value.submitted.profil &&
        items.value.submitted.profil.imageUrl
          ? items.value.submitted.profil.imageUrl
          : require('@/assets/images/no-profile.jpg');
    });

    return {
      currentTab,
      requestSections,
      requestProfile,
      table,
      profileImage,
    };
  },
});
