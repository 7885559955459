
import { storeToRefs } from 'pinia';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { useCouponImageStore } from '@/store/useCouponImage';
import Cropper from 'cropperjs';

export default defineComponent({
  name: 'coupon-cropper',
  props: {
    item: {
      required: true,
      type: Object,
    },
    rotation: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {
    const couponImage = useCouponImageStore();
    const { imageObj, uploadStatus, uploadError } = storeToRefs(couponImage);
    const { uploadImage } = useCouponImageStore();

    const canvas = ref(null);
    const cropper = ref(null);
    const preciseRotation = ref(0);

    const rotation = ref(0);

    onMounted(() => {
      if (canvas.value != undefined) {
        const context = canvas.value.getContext('2d');
        imageObj.value = new Image();
        imageObj.value.crossOrigin = 'Anonymous';
        imageObj.value.onload = () => {
          if (rotation.value >= 360) rotation.value = 0;
          if (rotation.value % 180) {
            canvas.value.width = imageObj.value.height;
            canvas.value.height = imageObj.value.width;
          } else {
            canvas.value.width = imageObj.value.width;
            canvas.value.height = imageObj.value.height;
          }
          context.rotate((rotation.value * Math.PI) / 180);
          if (rotation.value === 90) {
            context.translate(0, -canvas.value.width);
          } else if (rotation.value === 180) {
            context.translate(-canvas.value.width, -canvas.value.height);
          } else if (rotation.value === 270) {
            context.translate(-canvas.value.height, 0);
          }
          context.drawImage(imageObj.value, 0, 0);
          cropper.value = new Cropper(canvas.value, {
            autoCrop : true,
            background: true,
            autoCropArea: 1,
          });
        };
        imageObj.value.src = props.item.url;
      }
    });

    watch([preciseRotation], (value) => {
      if(value) {
        cropper.value.rotateTo(value);
      }
    });

    const rotate = (deg) => {
      rotation.value = (rotation.value + deg) % 360;
      preciseRotation.value = rotation.value;
      const containerData = cropper.value.getContainerData();
      const newData = {
        height: 2,
        width: 2,
        top: 0,
        left: (containerData.width / 2) - 1,
      }

      cropper.value.setCropBoxData(newData);
      cropper.value.rotate(deg);

      const canvasData = cropper.value.getCanvasData();
      const newWidth = canvasData.width * (containerData.height / canvasData.height);
      let newCanvasData = {
        height: containerData.height,
        width: newWidth,
        top: 0,
        left: (containerData.width - newWidth) / 2,
      };

      if (newWidth >= containerData.width) {
        const newHeight = canvasData.height * (containerData.width / canvasData.width);
        newCanvasData = {
          height: newHeight,
          width: containerData.width,
          top: (containerData.height - newHeight) / 2,
          left: 0,
        }
      }

      cropper.value.setCanvasData(newCanvasData);
      cropper.value.setCropBoxData(newCanvasData);
    };

    const close = (url = null) => {
      context.emit('close', url);
    };
    const upload = () => {
      let cvn = cropper.value.getCroppedCanvas({
        maxWidth: 1000,
        maxHeight: 1000,
      });

      cvn.toBlob(async (blob) => {
        try {
          const downloadUrl = await uploadImage(blob, props.item);
          close(downloadUrl);
        } catch (error) {
          console.log(error);
        }
      });
    };

    return {
      close,
      upload,
      uploadImage,
      rotate,
      uploadStatus,
      uploadError,
      canvas,
      cropper,
      preciseRotation,
    };
  },
});
