import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_badge = _resolveComponent("v-badge")!

  return (_openBlock(), _createBlock(_component_v_badge, {
    color: $setup.color,
    inline: "",
    dot: "",
    right: ""
  }, null, 8, ["color"]))
}