
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useCouponStore } from '@/store/useCoupon';
import { storeToRefs } from 'pinia';

export default defineComponent({
  setup() {
    const coupon = useCouponStore();
    const { refreshTime, listAccounts } = storeToRefs(coupon);
    const { couponsOnCall } = useCouponStore();
    const unsubCoupons = ref(null);
    const unsubErrorCoupons = ref(null);

    onMounted(async () => {
      refreshOdooListAccounts();
      unsubCoupons.value = await coupon.fetchCoupons();
      unsubErrorCoupons.value = await coupon.fetchErrorCoupons();
    });

    const countDownTimer = ref(1);
    const refreshOdooListAccounts = async () => {
      if (countDownTimer.value > 0) {
        setTimeout(() => {
          countDownTimer.value -= 1;
          refreshOdooListAccounts();
        }, 1000);
      }

      if (countDownTimer.value <= 0) {
        const today = new Date();
        countDownTimer.value = 600;
        refreshOdooListAccounts();
        const lsRefresh = parseInt(localStorage.getItem('accounts-refresh-time')) || 0;
        refreshTime.value = lsRefresh || refreshTime.value;
        if (refreshTime.value < today.setHours(today.getHours())) {
          const data = await couponsOnCall('', 'odoo-listAccounts');
          const { refresh, accounts } = JSON.parse(data);
          refreshTime.value = refresh;
          listAccounts.value = accounts;
          localStorage.setItem('accounts-data', JSON.stringify(accounts));
          localStorage.setItem('accounts-refresh-time', refresh);
        } else if (listAccounts.value.length === 0)  {
          const accounts = JSON.parse(localStorage.getItem('accounts-data')) || [];
          listAccounts.value = accounts;
        }
      }
    };

    onUnmounted(async () => {
      await unsubCoupons.value();
      await unsubErrorCoupons.value();
    });

    return {
      couponsOnCall,
      refreshOdooListAccounts,
      refreshTime,
      listAccounts,
      countDownTimer
    };
  },
});
