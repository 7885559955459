import _ from 'lodash';

function identify(items, width) {
  const objects = [
    {
      leftMin: 0,
      leftMax: 0.07,
      type: 'couponNumber',
      label: '# Coupon',
    },
    {
      leftMin: 0.54,
      leftMax: 0.63,
      type: 'accountNumber',
      label: '# Compte',
    },
  ];
  return items.map((item) => {
    const position = item.rectangle.left / width;
    const candidate = objects.find(o => position < o.leftMax && position > o.leftMin);
    const object = candidate
      ? {
        type: candidate.type,
        label: candidate.label,
      }
      : null;
    return { ...item, object };
  });
}

function textTransform(text) {
  return text
    .trim()
    .split(' ')
    .join('')
    .split('I')
    .join('1')
    .split('L')
    .join('1')
    .split('S')
    .join('5')
    .split('?')
    .join('7')
    .split('O')
    .join('0');
}

function minMax(array, predicate) {
  return {
    min: _.minBy(array, predicate)[predicate],
    max: _.maxBy(array, predicate)[predicate],
  };
}

function isNear(a, b) {
  const maxDistance = 15;
  return a.rectangle.left + a.rectangle.width + maxDistance >= b.rectangle.left;
}

function concat(a, b) {
  return {
    text: a.text + b.text,
    rectangle: {
      ...a.rectangle,
      left: a.rectangle.left,
      width: b.rectangle.left + b.rectangle.width - a.rectangle.left,
    },
  };
}

function merge(results) {
  const newSet = [];
  let [head, ...tail] = results;
  while (head) {
    if (tail && tail.length && isNear(head, tail[0])) {
      head = concat(head, tail[0]);
      tail = tail.slice(1);
    } else {
      newSet.push(head);
      [head, ...tail] = tail;
    }
  }
  return newSet;
}

export default function (results, width) {
  /* first result gives the big rectangle */
  const verticalBounds = minMax(results[0].boundingPoly.vertices, 'y');

  /* then normalize other results */
  let newSet = results.slice(1);
  newSet = newSet.map((result) => {
    const horizontalBounds = minMax(result.boundingPoly.vertices, 'x');
    return {
      text: result.description,
      rectangle: {
        top: verticalBounds.min,
        left: horizontalBounds.min,
        width: horizontalBounds.max - horizontalBounds.min,
        height: verticalBounds.max - verticalBounds.min,
      },
    };
  });

  /* then merge the rectangles that are close to each other */
  newSet = merge(newSet);

  /* let's get some padding (the vertices sounds bad sometimes) */
  const padding = 10;
  newSet = newSet.map(s => ({
    ...s,
    rectangle: {
      ...s.rectangle,
      left: s.rectangle.left - padding,
      width: s.rectangle.width + padding * 2,
    },
  }));

  /* let's arrange some texts */
  newSet = newSet.map(s => ({
    ...s,
    text: textTransform(s.text),
  }));

  /* let's find the propabiblity of something is what we want */
  newSet = identify(newSet, width);

  return newSet;
}
