import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  style: {"background-color":"yellow"}
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "py-4"
}
const _hoisted_6 = {
  key: 1,
  class: "py-4"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { colspan: "2" }
const _hoisted_9 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_fs_lightbox = _resolveComponent("fs-lightbox")!
  const _component_request_approbation_button = _resolveComponent("request-approbation-button")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_table, { class: "section-table" }, {
        default: _withCtx(() => [
          (_ctx.itemData.submitted != undefined)
            ? (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columnSubField, (value, idx) => {
                  return (_openBlock(), _createElementBlock("tr", { key: idx }, [
                    _createElementVNode("th", null, _toDisplayString(value.label), 1),
                    (value.field !== 'imageUrl')
                      ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                          (!_ctx.submitted && _ctx.columnField !== 'vehicleCertificate')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_3, " - "))
                            : _createCommentVNode("", true),
                          (value.field !== 'imageUrl')
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                style: _normalizeStyle({ 'background-color': _ctx.submitted[value.field] ? '' : _ctx.color })
                              }, _toDisplayString(_ctx.submitted[value.field] || '-'), 5))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("td", _hoisted_4, [
                          (_ctx.submitted !== undefined)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                (Object.prototype.hasOwnProperty.call(_ctx.submitted, 'imageUrl'))
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_v_img, {
                                        class: "img",
                                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggler = !_ctx.toggler)),
                                        src: _ctx.submitted.imageUrl,
                                        "lazy-src": _ctx.submitted.imageUrl,
                                        "max-width": "400px",
                                        cover: ""
                                      }, null, 8, ["src", "lazy-src"]),
                                      _createVNode(_component_fs_lightbox, {
                                        toggler: _ctx.toggler,
                                        sources: [_ctx.submitted.imageUrl]
                                      }, null, 8, ["toggler", "sources"])
                                    ], 64))
                                  : (_openBlock(), _createBlock(_component_v_img, {
                                      key: 1,
                                      class: "img",
                                      src: require('@/assets/images/no-image.jpg'),
                                      "lazy-src": require('@/assets/images/no-image.jpg'),
                                      "max-width": "400px",
                                      cover: ""
                                    }, null, 8, ["src", "lazy-src"]))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                _createVNode(_component_v_img, {
                                  class: "img",
                                  src: require('@/assets/images/no-image.jpg'),
                                  "lazy-src": require('@/assets/images/no-image.jpg'),
                                  "max-width": "400px",
                                  cover: ""
                                }, null, 8, ["src", "lazy-src"])
                              ]))
                        ]))
                  ]))
                }), 128)),
                (_ctx.itemData.approvals.globalStatus !== 'deleted')
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                      _createElementVNode("td", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          (_ctx.columnField !== 'status')
                            ? (_openBlock(), _createBlock(_component_request_approbation_button, {
                                key: 0,
                                section: _ctx.approvals,
                                id: _ctx.columnField,
                                docId: _ctx.itemData.id,
                                requestType: _ctx.requestType
                              }, null, 8, ["section", "id", "docId", "requestType"]))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}