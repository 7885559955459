
    import { defineComponent } from 'vue';

    export default defineComponent({
        props: {
            item: {
                type: Object,
                required: true,
                default: null
            },
            requestType: {
                type: String,
                required: true,
                default: '',
            }
        },
    });

