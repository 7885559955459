
    import { defineComponent } from 'vue';
    /* eslint-disable */
    export default defineComponent({
        props: {
            sort: {
                type: String,
                required: false
            },
            dir: {
                type: String,
                required: false
            },
            items: {
                type: Array,
                default: [] as Array<any>
            },
            tableName: {
                type: Array,
                default: [] as Array<any>
            },
            loading: {
                type: Boolean,
                default: true
            }
        },
    })
