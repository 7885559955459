
import { defineComponent, onMounted, ref, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRegistrationStore } from '@/store/useRegistration';

export default defineComponent({
  setup() {
    const registration = useRegistrationStore();
    const { requestItems } = storeToRefs(registration);
    const { fetchRequestItems, deleteRequestItem } = useRegistrationStore();
    const unsubRequests = ref(null);

    onMounted(async () => {
      unsubRequests.value = await fetchRequestItems();
    });

    onUnmounted(async () => {
      await unsubRequests.value();
    });

    return {
      requestItems,
      fetchRequestItems,
      deleteRequestItem,
    };
  },
});
